import reduxlogger from "redux-logger";

//Added
import {
  configureStore,
  isRejectedWithValue,
  isRejected,
} from "@reduxjs/toolkit";
import calenderReducer from "../../redux/reducers/calenderReducer";
import emailReducer from "../../redux/reducers/email/";
import chatReducer from "../../redux/reducers/chat/";
import contactsReducer from "../../redux/reducers/contacts/";
import todoReducer from "../../redux/reducers/todo/";
import customizer from "../../redux/reducers/customizer/";
import usersReducer from "../../redux/reducers/users/usersReducer";
import eventsReducer from "../../redux/reducers/events/eventsReducer";
import statementBuilderReducer from "../../redux/reducers/statementBuilder/statementBuilderReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import listSettingsReducer from "../../redux/reducers/settings/listSettingsReducer";
import moduleAccessReducer from "../../redux/reducers/users/moduleAccessReducer";
import betListReducer from "../../redux/reducers/bets/betListReducer";
import clientsReducer from "../../redux/reducers/clients/clientsReducer";
import clientsprofileReducer from "../../redux/reducers/clients/clients";
import { reducer as bonusBetReducer } from "../BonusBets";
import { pendingWithDrawalReducer } from "../../redux/reducers/finance/withdrawal/pendingWithdrawalReducer";
import withdrawalReducer from "../../redux/reducers/finance/withdrawal/withdrawals";
import { reducer as clientInfoReducer } from "../ClientInfo";
import { adjustmentReducer } from "../../redux/reducers/finance/adjustment/adjustmentReducer";
import { getbroadcastMessages } from "../../redux/reducers/broadcast/broadcastMessageReducer";
import { reducer as systemSettingReducer } from "../SystemSettings";
import configureSystemSettingsReducer from "../ConfigureSystemSetting";
import { reducer as reportReducer } from "../actions/reports/ReportInfo";
import { reducer as pendingReducer } from "../actions/bets/pendingBetsInfo";
import { reducer as authenticationReducer } from "../Authentication";
import { reducer as mediaInfoReducer } from "../MediaInfo.js";
import { reducer as chatsReducer } from "../Chats";
import { reducer as categoriesReducer } from "../Categories";
import parentCategoryReducer from "../../redux/reducers/parentCategory/parentCategoryReducer";
import venueReducer from "../../redux/reducers/venue/venueReducer";
import teamReducer from "../../redux/reducers/team/teamReducer";
import { cmsReducer } from "../../redux/reducers/cms/index";
import divisionReducer from "../../redux/reducers/division/divisionReducer";
import logger from "../reducers/logger";
import messenger from "../../redux/reducers/messenger";
import BonusBetsclsReducer from "../../redux/reducers/bonusbets/BonusBetsclsReducer";
import commentsReducer from "../../redux/reducers/chat/chats";
import { commentsReplyReducer } from "../../redux/reducers/chat/chats";
import { getBetReasonsDataReducer } from "../reducers/betReasons/index";
import { getCustomizedBetReasonsDataReducer } from "../reducers/betReasons/customizedBetReasons";
import { toggleSidebar } from "../../redux/reducers/sideMenuToggle/index";
import GBCatogoriesReducer from "../reducers/groupBetting/gb-catogories";
import GBOptionsReducer from "../reducers/groupBetting/gb-options";
import GBResultingReducer from "../reducers/groupBetting/gb-resulting";
import TippingReducer from "../reducers/tipping/tipping";
import CMSReducer from "../reducers/cms/cms";
import categoriesSliceReducer from "../CategoriesReducer";
import browserHistory from "../../history";
import { toastr } from "react-redux-toastr";
import betListReducers from "../reducers/bets/betListReducers";
import { isNotNullandUndefined } from "../../commons";
import ConsoleGamingReducer from "../reducers/consoleGaming/console-gaming";

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejected(action)) {
    if (
      isNotNullandUndefined(action.error) &&
      action.error.message === "Request failed with status code 401"
    ) {
      toastr.error("Session expired! Please login again");
      browserHistory.replace(`/pages/login`);
    } else if (
      isNotNullandUndefined(action.error) &&
      action.error.name === "TypeError"
    ) {
      toastr.error("Error while reading response");
    }
  }
  if (isRejectedWithValue(action)) {
    if (action.payload.errors[0].code === 5001) {
      toastr.error("Session expired! Please login again");
      browserHistory.replace(`/pages/login`);
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    calender: calenderReducer,
    emailApp: emailReducer,
    contactApp: contactsReducer,
    todoApp: todoReducer,
    toastr: toastrReducer, // <- Mounted at toastr.
    chatApp: chatReducer,
    customizer: customizer,
    usersApp: usersReducer,
    eventsApp: eventsReducer,
    statementBuilderApp: statementBuilderReducer,
    listSettingsApp: listSettingsReducer,
    moduleAccessApp: moduleAccessReducer,
    betListApp: betListReducer,
    betListData: betListReducers,
    BonusBetsclsApp: BonusBetsclsReducer,
    clientsApp: clientsReducer,
    clientsprofileApp: clientsprofileReducer,
    bonusBets: bonusBetReducer,
    pendingWithDrawals: pendingWithDrawalReducer,
    withdrawalData: withdrawalReducer,
    clientInfo: clientInfoReducer,
    adjustments: adjustmentReducer,
    broadcastMessages: getbroadcastMessages,
    systemSettings: systemSettingReducer,
    configureSystemSettings: configureSystemSettingsReducer,
    reportsInfo: reportReducer,
    pendingBetsInfo: pendingReducer,
    auth: authenticationReducer,
    mediaInfo: mediaInfoReducer,
    chats: chatsReducer,
    categories: categoriesReducer,
    categoriesApp: categoriesSliceReducer,
    parentCategory: parentCategoryReducer,
    venue: venueReducer,
    team: teamReducer,
    cms: cmsReducer,
    division: divisionReducer,
    messenger,
    commentsReducer,
    commentsReplyReducer,
    getBetReasonsDataReducer,
    getCustomizedBetReasonsDataReducer,
    toggleSidebar,
    gbCatogories: GBCatogoriesReducer,
    gbMarketOptions: GBOptionsReducer,
    gbResulting: GBResultingReducer,
    tippingData: TippingReducer,
    cmsData: CMSReducer,
    logger,
    consoleGaming: ConsoleGamingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(reduxlogger)
      .prepend(rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV === "development",
});

export { store };
