import * as yup from "yup";
import { isNotNullEmptyandUndefined } from "./commons";

let regionlist = {    
  AU:"AU",
  US:"US",
  IE:"IE",
  ON:"ON",
  UK:"UK",
  GB:"GB",
  CA:"CA",
  ML:"ML",
}; 

let stateslist = {    
  CO:"CO", //Colorado
  AB:"AB", //Alberta
  ON:"ON", //Ontario
};

let betTypelist = {    
  Jackpot:"Jackpot",
  Golf:"Golf",
  TippingComp:"TippingComp",
  ConsoleGame:"ConsoleGame",
  Cash:"Cash",
  Handshake:"Handshake",  
};

const COUNTRY_NAME_REGEX = /^[A-Za-z]*$/;
const REQUIRED_ERROR_MESSAGE = "Required Field";
const MIN3_ERROR_MESSAGE = "Must have 2 characters";
// const MIN_MAX_ERROR_MESSAGE = "Must have 6- 30 characters";
const TOO_SMALL_ERROR_MESSAGE = "The Name must contain at least 3 characters";
const INVALID_FORMAT_ERROR_MESSAGE = "The Name may contain only letters";

// #Phone
const REQUIRED_PHONE_ERROR_MESSAGE = "Phone No. Required";
const INVALID_PHONE_ERROR_MESSAGE = "Phone number is invalid";
const INVALID_AU_PHONE_FORMAT_ERROR_MESSAGE =
  "Phone number must be 10 digits starting with zero";
const INVALID_US_PHONE_FORMAT_ERROR_MESSAGE = "Phone number must be 17 digits";
const INVALID_CA_PHONE_VAL1_FORMAT_ERROR_MESSAGE =
  "Phone number must be 10 digits";
  const INVALID_IE_PHONE_VAL_FORMAT_ERROR_MESSAGE =
  "Phone number must be 10 digits";
const INVALID_CA_PHONE_VAL2_FORMAT_ERROR_MESSAGE =
  "Phone number must be 10 digits,can not start with 0 or 1";
const INVALID_IE_PHONE_FORMAT_ERROR_MESSAGE = "Phone number must be 10 digits";
const INVALID_ON_PHONE_FORMAT_ERROR_MESSAGE = "Phone number must be 10 digits";

//10 Digits with 0 at Start -phone AU
const phone_au = /\b^0\d{9}\b/;
const phone_ca =
  /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/;
const phone_ca_not0and1atStart = /^[2-9][0-9]*$/;
const phone_uk = /^\(?\d{3}\)?[-\.\s]?\d{4}[-\.\s]?\d{4}$/; 
//is 17 digits For US?
//const phone_us = /\+?[\d]?\s*\(?\d{3}\)?[\s\.-]?\d{3}[\s\.-]?\d{4}/;
const phone_us = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
const phone_ie = /\b^0\d{9}\b/;
const phone_ie1 =
  /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/;

// #Email
const INVALIDEMAIL_MESSAGE = "Invalid email";
const REQUIRED_EMAIL_ERROR_MESSAGE = "Email Required";
//Email pattern
const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// #Name
const REQUIRED_FIRSTNAME_ERROR_MESSAGE = "First Name Required";
const REQUIRED_LASTNAME_ERROR_MESSAGE = "Last Name Required";
const REQUIRED_USERNAME_ERROR_MESSAGE = "User Name Required";
const INVALID_USERNAME_FORMAT_ERROR_MESSAGE = `Invalid Username - Username should accept only 6–30 characters. It cannot accept any spaces, only numbers & special characters. It can accept upper/lower case characters`;

// #Postcode
const REQUIRED_POSTCODE_ERROR_MESSAGE = "Post code Required";
const INVALID_POSTCODE_ERROR_MESSAGE = "Post code is invalid";
const INVALID_AU_POSTCODE_FORMAT_ERROR_MESSAGE = "Post code must be 4 digits";
const INVALID_CA_POSTCODE_FORMAT_ERROR_MESSAGE = "Post code must be 6 digits";
const INVALID_UK_POSTCODE_FORMAT_ERROR_MESSAGE = "Post code must have 6-8 alphanumeric characters";
const INVALID_IE_POSTCODE_FORMAT_ERROR_MESSAGE = "Post code must have 5-8 alphanumeric characters";
const INVALID_US_POSTCODE_FORMAT_ERROR_MESSAGE = "Postcode is invalid";
const INVALID_ML_POSTCODE_FORMAT_ERROR_MESSAGE = "Postcode is invalid";
const INVALID_ON_POSTCODE_FORMAT_ERROR_MESSAGE = "Postcode is invalid";

//4 Digits postcode -post code AU
const postcode_au = /\b\d{4}\b/;
//const postcode_ca = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
const postcode_ca = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const postcode_on = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
//const postcode_on = /[A-Z]\d[A-Z]\s?\d[A-Z]\d/; //alternative

const postcode_ml = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
const postcode_us = /\b\d{4}\b/;
const postcode_ie = /^[A-Za-z 0-9_]{6,8}$/;
//const postcode_on = /\b\d{4}\b/;
//const postcode_uk = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
const postcode_uk = /^[A-Za-z 0-9_]{6,8}$/;

// #Adress
const REQUIRED_HOUSEUNIT_ERROR_MESSAGE = "House Number Required";
const REQUIRED_STREET_ERROR_MESSAGE = "Street Required";
const REQUIRED_CITY_ERROR_MESSAGE = "City Required";
const REQUIRED_COUNTRY_ERROR_MESSAGE = "Country Required";
const REQUIRED_STATE_ERROR_MESSAGE = "State Required";

// #Date of birth
const REQUIRED_DOB_ERROR_MESSAGE = "DOB Required";
const REQUIRED_DOB_OVER18_ERROR_MESSAGE = "You must be 18 or over";
const REQUIRED_DOB_OVER19_ERROR_MESSAGE = "You must be 19 or over";

//client Withdrawal
const accountNumber_numeric = /^[0-9-+]+$/;
const branchTransitNumber_length = /^(\d{5,12})$/;
const accountNumber_ca_length = /^(\d{5,12})$/;
const bankcode_ca =/\b\d{3}\b/;

// Names without spaces
const username_format = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,30}$/;

// Names with spaces
const us_account_firstname_length=/^[A-Za-z][A-Za-z 0-9_]{2,44}$/;
const us_account_lastname_length=/^[A-Za-z][A-Za-z 0-9_]{2,44}$/;
const us_account_bankname_length=/^[A-Za-z][A-Za-z 0-9_]{2,44}$/;
const us_accountNumber_length = /^(\d{2,17})$/;
const us_abaRoutingCode_length = /^(\d{9,9})$/;
const us_postcode = /^[A-Za-z 0-9_]{5,9}$/;

const uk_account_firstname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const uk_account_lastname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const uk_account_bankname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const uk_accountNumber_length = /^(\d{8,8})$/;
const uk_abaRoutingCode_length = /^(\d{6,6})$/;
const uk_sortCode_length = /^(\d{6,6})$/;
const uk_amount_length = /^(\d{1,10})$/;

const ie_account_firstname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const ie_account_lastname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const ie_account_bankname_length=/^[A-Za-z][A-Za-z 0-9_]{2,49}$/;
const ie_account_iban=/^[A-Za-z 0-9_]{22,22}$/;
const ie_account_swiftBic=/^[A-Za-z 0-9_]{8,11}$/;

export const IsRequiredField = (searchTerm , selectedCountry) =>
getClintValidationField(selectedCountry).find((options) => options.key === searchTerm).value;


function validateIbanChecksum(iban) {       
  const ibanStripped = iban.replace(/[^A-Z0-9]+/gi,'') //keep numbers and letters only
                           .toUpperCase(); //calculation expects upper-case
  const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
  if(!m) return false;
  
  const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g,function(ch){
                        //replace upper-case characters by numbers 10 to 35
                        return (ch.charCodeAt(0)-55); 
                    });
  //The resulting number would be to long for javascript to handle without loosing precision.
  //So the trick is to chop the string up in smaller parts.
  const mod97 = numbericed.match(/\d{1,7}/g)
                          .reduce(function(total, curr){ return Number(total + curr)%97},'');

  return (mod97 === 1);
};
// let str ='DE89 3704 0044 0532 0130 00';
// console.log('['+ str +'] Valid IBAN Checksum: '+ validateIbanChecksum(str))

function isOver18(message) {
  return this.test("isOver18", message, function (value) {
    const { path, createError } = this;
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);

    if (!value) {
      return createError({
        path,
        message: message ? REQUIRED_DOB_ERROR_MESSAGE : "",
      });
    }

    if (!(new Date(value) <= date18YrsAgo)) {
      return createError({
        path,
        message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
      });
    }
    return true;
  });
}
yup.addMethod(yup.mixed, "isOver18", isOver18);

function isValidAge(message, selectedCountry) {
  return this.test("isValidAge", message, function (value) {
    const { path, createError } = this;
    const date19YrsAgo = new Date();
    date19YrsAgo.setFullYear(date19YrsAgo.getFullYear() - 19);
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);

    if (!value) {
      return createError({
        path,
        message: message ? REQUIRED_DOB_ERROR_MESSAGE : "",
      });
    }

    switch (selectedCountry) {
      case regionlist.CA:
        if(stateslist.ON === this.parent.state){
        if (!(new Date(value) <= date19YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER19_ERROR_MESSAGE : "",
          });
        }}
        else{
          if (!(new Date(value) <= date18YrsAgo)) {
            return createError({
              path,
              message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
            });
          }
        }
        break; 
             
      case regionlist.GB:
        if (!(new Date(value) <= date18YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.ML:
        if (!(new Date(value) <= date18YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.AU:
        if (!(new Date(value) <= date18YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
          });
        }
        break;
      
      case regionlist.IE:
        if (!(new Date(value) <= date18YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER18_ERROR_MESSAGE : "",
          });
        }
        break;
      case stateslist.ON:
        if (!(new Date(value) <= date19YrsAgo)) {
          return createError({
            path,
            message: message ? REQUIRED_DOB_OVER19_ERROR_MESSAGE : "",
          });
        }
        break;
    }
    return true;
  });
}
yup.addMethod(yup.mixed, "isValidAge", isValidAge);

function isValidUserName(message, selectedCountry) {
  return this.test("isValidUserName", message, function (value) {
    const { path, createError } = this;
    let existingUserName = this.parent.firstName+" "+this.parent.lastName;
    if (value && (value !== existingUserName) && !value.match(username_format)) {
      return createError({
        path,
        message: message ? INVALID_USERNAME_FORMAT_ERROR_MESSAGE : "",
      });
    }
    return true;
  });
}

yup.addMethod(yup.mixed, "isValidUserName", isValidUserName);

function isValidPostcode(message, selectedCountry) {
  return this.test("isValidPostcode", message, function (value) {
    const { path, createError } = this;
    
    if (!value) {
      return createError({
        path,
        message: message ? REQUIRED_POSTCODE_ERROR_MESSAGE : "",
      });
    }

    switch (selectedCountry) {
      case regionlist.CA:
        if(stateslist.ON === this.parent.state){}
        if (!value.match(postcode_ca)) {
          return createError({
            path,
            message: message ? INVALID_CA_POSTCODE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;        
      case regionlist.GB:
        if (!value.match(postcode_uk)) {
          return createError({
            path,
            message: message ? INVALID_UK_POSTCODE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.ML:
        if (!value.match(postcode_ml)) {
          return createError({
            path,
            message: message ? INVALID_POSTCODE_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.AU:
        if (!value.match(postcode_au)) {
          return createError({
            path,
            message: message ? INVALID_AU_POSTCODE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      // case regionlist.US:
      //   if (!value.match(postcode_us)) {
      //     return createError({
      //       path,
      //       message: message ? INVALID_POSTCODE_ERROR_MESSAGE:""
      //     });
      //   }
      //   break;
      case regionlist.IE:
        if (!value.match(postcode_ie)) {
          return createError({
            path,
            message: message ? INVALID_IE_POSTCODE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.ON:
        if (!value.match(postcode_on)) {
          return createError({
            path,
            message: message ? INVALID_POSTCODE_ERROR_MESSAGE : "",
          });
        }
        break;
    }
    return true;
  });
}
yup.addMethod(yup.mixed, "isValidPostcode", isValidPostcode);



function isValidPhone(message, selectedCountry) {
  return this.test("isValidPhone", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({
        path,
        message: message ? REQUIRED_PHONE_ERROR_MESSAGE : "",
      });
    }

    switch (selectedCountry) {
      case regionlist.CA:
        if (!value.match(phone_ca_not0and1atStart)) {
          return createError({
            path,
            message: message ? INVALID_CA_PHONE_VAL2_FORMAT_ERROR_MESSAGE : "",
          });
        }
        if (!value.match(phone_ca)) {
          return createError({
            path,
            message: message ? INVALID_CA_PHONE_VAL1_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.IE:
        if (!value.match(phone_ie)) {
          return createError({
            path,
            message: message ? INVALID_AU_PHONE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        if (!value.match(phone_ie1)) {
          return createError({
            path,
            message: message ? INVALID_IE_PHONE_VAL_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.ML:
        if (!value.match(phone_ca)) {
          return createError({
            path,
            message: message ? INVALID_CA_PHONE_VAL1_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.AU:
        if (!value.match(phone_au)) {
          return createError({
            path,
            message: message ? INVALID_AU_PHONE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.US:
        if (!value.match(phone_us)) {
          return createError({
            path,
            message: message ? INVALID_PHONE_ERROR_MESSAGE : "",
          });
        }
        break;
      case regionlist.GB:
        if (!value.match(phone_uk)) {
          return createError({
            path,
            message: message ? INVALID_PHONE_ERROR_MESSAGE : "",
          });
        }
        break;  
      case regionlist.ON:
        if (!value.match(phone_us)) {
          return createError({
            path,
            message: message ? INVALID_ON_PHONE_FORMAT_ERROR_MESSAGE : "",
          });
        }
        break;
    }
    return true;
  });
}
yup.addMethod(yup.mixed, "isValidPhone", isValidPhone);

function isValidCountry(message, selectedCountry) {
  return this.test("isValidCountry", message, function (value) {
    const { path, createError } = this;
    // console.log(value);
    // console.log(selectedCountry);
    if (!value) {
      return createError({
        path,
        message: message ? REQUIRED_ERROR_MESSAGE : "",
      });
    }

    if (value.length < 3) {
      return createError({
        path,
        message: message ? TOO_SMALL_ERROR_MESSAGE : "",
      });
    }

    if (!value.match(COUNTRY_NAME_REGEX)) {
      return createError({
        path,
        message: message ? INVALID_FORMAT_ERROR_MESSAGE : "",
      });
    }
    return true;
  });
}
yup.addMethod(yup.mixed, "isValidCountry", isValidCountry);

export const getClientValidationSchema = (selectedCountry) =>
  yup.object().shape({
    firstName: yup
      .string()
      .min(2, MIN3_ERROR_MESSAGE)
      .required(REQUIRED_FIRSTNAME_ERROR_MESSAGE),
    lastName: yup
      .string()
      .min(2, MIN3_ERROR_MESSAGE)
      .required(REQUIRED_LASTNAME_ERROR_MESSAGE),
    username: yup
      .mixed()      
      .isValidUserName(INVALID_USERNAME_FORMAT_ERROR_MESSAGE , selectedCountry),
    email: yup
      .string()
      .email(INVALIDEMAIL_MESSAGE)
      .matches(email, { message: INVALIDEMAIL_MESSAGE })
      .required(REQUIRED_EMAIL_ERROR_MESSAGE),
    phone: yup
      .mixed()
      .isValidPhone(INVALID_PHONE_ERROR_MESSAGE, selectedCountry)
      .required(REQUIRED_PHONE_ERROR_MESSAGE),
    unitAndHouseNumber: yup.string().required(REQUIRED_HOUSEUNIT_ERROR_MESSAGE),
    streetName: yup.string().required(REQUIRED_STREET_ERROR_MESSAGE),
    city: yup.string().required(REQUIRED_CITY_ERROR_MESSAGE),
    country: yup.string().required(REQUIRED_COUNTRY_ERROR_MESSAGE),    
    state: yup.string().when([], {
      is: () => IsRequiredField(clintValidationFields.state,selectedCountry),
      then: yup.string().required(REQUIRED_STATE_ERROR_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    postcode: yup
      .string()
      .isValidPostcode(INVALID_POSTCODE_ERROR_MESSAGE, selectedCountry)
      .required(REQUIRED_POSTCODE_ERROR_MESSAGE),
    dob: yup
      .string()
      .isValidAge(REQUIRED_DOB_OVER18_ERROR_MESSAGE,selectedCountry)
      .required(REQUIRED_DOB_ERROR_MESSAGE),
  });

  export const clintValidationFields = {
    state: "state",   
  };
  
  export const getClintValidationField = (selectedCountry) => {  
    const countryfilterSelections = (selectedCountryfilter) => {
      const CountryfilterValidations = [     
        { country: regionlist.AU, key: clintValidationFields.state, value: true },     
  
        { country: regionlist.US, key: clintValidationFields.state, value: true },        
  
        { country: regionlist.CA, key: clintValidationFields.state, value: true }, 
  
        { country: regionlist.ON, key: clintValidationFields.state, value: true },

        { country: regionlist.IE, key: clintValidationFields.state, value: false },        
  
        { country: regionlist.GB, key: clintValidationFields.state, value: false },        
      ];
  
      const filteredSelections = CountryfilterValidations.filter(
        (item) => item.country === selectedCountryfilter
      );  
      return filteredSelections;
    };
  
    switch (selectedCountry) {
      case regionlist.CA:
        return countryfilterSelections(regionlist.CA);
      case regionlist.AU:
        return countryfilterSelections(regionlist.AU);
      case regionlist.US:
        return countryfilterSelections(regionlist.US);
      case regionlist.IE:
        return countryfilterSelections(regionlist.IE);
      case regionlist.ON:
        return countryfilterSelections(regionlist.ON);
      case regionlist.GB:
        return countryfilterSelections(regionlist.GB);
      default:
        return [];
    }
  };

export const clintOptionSelections = {
  VERIFIED: "VERIFIED",
  CONSENT_TO_MARKETING: "CONSENT TO MARKETING",
  PRIVATE: "PRIVATE",
  CONSENT_TO_NAME_SEARCH: "CONSENT TO NAME SEARCH",
  CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS:
    "CONSENT TO SHOW MY BETS TO MY FOLLOWERS",
  IS_INFLUENCER: "IS INFLUENCER",
  EMAIL_VERIFIED: "EMAIL VERIFIED",
  THIRD_PARTY_SHARING_OPT_OUT: "THIRD-PARTY SHARING OPT-OUT",
  CONSENT_TO_MARKETING_VIA_SMS: "CONSENT TO MARKETING VIA SMS",
  CONSENT_TO_MARKETING_VIA_EMAIL: "CONSENT TO MARKETING VIA EMAIL",
  PEP_HIO_ATTESTATION: "PEP/HIO ATTESTATION",
  MFA_ENABLED: "MFA Enabled",
};

export const getClintOptionSelections = (selectedCountry,selectedState) => {

  const countryfilterSelections = (selectedCountryfilter, selectedStatefilter) => {
    const CountryfilterValidations = [     
      { country: regionlist.AU, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.AU, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.AU, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.AU, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.AU, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.AU, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.AU, key: clintOptionSelections.EMAIL_VERIFIED,  value: false},
      { country: regionlist.AU, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: false },
      { country: regionlist.AU, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.AU, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.AU, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.AU, key: clintOptionSelections.MFA_ENABLED, value: false },

      { country: regionlist.US, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.US, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.US, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.US, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.US, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.US, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.US, key: clintOptionSelections.EMAIL_VERIFIED,  value: true},
      { country: regionlist.US, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: true },
      { country: regionlist.US, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.US, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.US, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.US, key: clintOptionSelections.MFA_ENABLED, value: false },

      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.EMAIL_VERIFIED,  value: true},
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: true },
      { country: regionlist.CA, state: stateslist.ON, key: clintOptionSelections.MFA_ENABLED, value: true },

      { country: regionlist.CA, state: "", key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.CA, state: "", key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.EMAIL_VERIFIED,  value: true},
      { country: regionlist.CA, state: "", key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: false },
      { country: regionlist.CA, state: "", key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.CA, state: "", key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.CA, state: "", key: clintOptionSelections.MFA_ENABLED, value: false },

      { country: regionlist.IE, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.IE, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.IE, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.IE, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.IE, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.IE, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.IE, key: clintOptionSelections.EMAIL_VERIFIED,  value: false},
      { country: regionlist.IE, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: true },
      { country: regionlist.IE, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.IE, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.IE, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.IE, key: clintOptionSelections.MFA_ENABLED, value: false },

      { country: regionlist.ON, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.ON, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.ON, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.ON, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.ON, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.ON, key: clintOptionSelections.EMAIL_VERIFIED,  value: true},
      { country: regionlist.ON, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: true },
      { country: regionlist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.ON, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.ON, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.ON, key: clintOptionSelections.MFA_ENABLED, value: false },

      { country: regionlist.GB, key: clintOptionSelections.VERIFIED, value: true },
      { country: regionlist.GB, key: clintOptionSelections.CONSENT_TO_MARKETING,  value: true },
      { country: regionlist.GB, key: clintOptionSelections.PRIVATE,  value: true},
      { country: regionlist.GB, key: clintOptionSelections.CONSENT_TO_NAME_SEARCH, value: true },
      { country: regionlist.GB, key: clintOptionSelections.CONSENT_TO_SHOW_MY_BETS_TO_MY_FOLLOWERS, value: true },
      { country: regionlist.GB, key: clintOptionSelections.IS_INFLUENCER,  value: true },
      { country: regionlist.GB, key: clintOptionSelections.EMAIL_VERIFIED,  value: false},
      { country: regionlist.GB, key: clintOptionSelections.THIRD_PARTY_SHARING_OPT_OUT, value: false },
      { country: regionlist.GB, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_SMS, value: true },
      { country: regionlist.GB, key: clintOptionSelections.CONSENT_TO_MARKETING_VIA_EMAIL, value: true },
      { country: regionlist.GB, key: clintOptionSelections.PEP_HIO_ATTESTATION, value: false },
      { country: regionlist.GB, key: clintOptionSelections.MFA_ENABLED, value: false },
    ];

    const filteredSelections = CountryfilterValidations.filter(
      (item) => (item.country === selectedCountryfilter && item.state === selectedStatefilter)
    );

    return filteredSelections;
  };

  switch (selectedCountry) {
    case regionlist.CA:
      if (
        stateExclusionList.filter(
          (include) =>
            include.country === regionlist.CA &&
            include.displayStates.some((r) => r.value === selectedState)
        ).length
      ) {
        return countryfilterSelections(regionlist.CA, selectedState);
      } else {
        return countryfilterSelections(regionlist.CA, "");
      }
    case regionlist.AU:
      return countryfilterSelections(regionlist.AU);
    case regionlist.US:
      return countryfilterSelections(regionlist.US);
    case regionlist.IE:
      return countryfilterSelections(regionlist.IE);
    case regionlist.ON:
      return countryfilterSelections(regionlist.ON);
    case regionlist.GB:
      return countryfilterSelections(regionlist.GB);
    default:
      return [];
  }
};


export const getFilteredCountryRules = (selectedCountry) => {
  const CMSContentTabs = {
    promoTile: "Promo tile",
    featureEvents: "Featured Events",
  };

  const countryfilterRules = (selectedCountryfilter) => {
    const CountryfilterValidations = [
      {
        country: regionlist.AU,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.AU,
        key: CMSContentTabs.featureEvents,
        value: true,
        text: "FEATURED EVENTS",
      },
      {
        country: regionlist.US,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.US,
        key: CMSContentTabs.featureEvents,
        value: true,
        text: "FEATURED EVENTS",
      },
      {
        country: regionlist.CA,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.IE,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.IE,
        key: CMSContentTabs.featureEvents,
        value: true,
        text: "FEATURED EVENTS",
      },
      {
        country: regionlist.ON,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.ON,
        key: CMSContentTabs.featureEvents,
        value: true,
        text: "FEATURED EVENTS",
      },
      {
        country: regionlist.GB,
        key: CMSContentTabs.promoTile,
        value: true,
        text: "PROMO TILES",
      },
      {
        country: regionlist.GB,
        key: CMSContentTabs.featureEvents,
        value: true,
        text: "FEATURED EVENTS",
      },
    ];

    const filteredRules = CountryfilterValidations.filter(
      (item) => item.country === selectedCountryfilter
    );

    return filteredRules;
  };

  switch (selectedCountry) {
    case regionlist.CA:
      return countryfilterRules(regionlist.CA);
    case regionlist.AU:
      return countryfilterRules(regionlist.AU);
    case regionlist.US:
      return countryfilterRules(regionlist.US);
    case regionlist.IE:
      return countryfilterRules(regionlist.IE);
    case regionlist.ON:
      return countryfilterRules(regionlist.ON);
    case regionlist.GB:
      return countryfilterRules(regionlist.GB);
    default:
      return [];
  }
};

export const stateExclusionList = [
  {
    country: regionlist.CA,
    states: ["Alberta"],
    displayStatesforBetIDCreation: true,    
    displayStates:[
      // {        
      //   "value": "AB",
      //   "label": "Alberta"
      // },      
      {       
        "value": "ON",
        "label": "Ontario"
      }     
    ],
    IsStateValueRequired: false, 
  },
  {
    country: regionlist.US,
    states: [],
    displayStatesforBetIDCreation: true,
    displayStates:[{     
      "value": "CO",
      "label": "Colorado"
    }],
    IsStateValueRequired: true,
  },
  {
    country: regionlist.AU,
    states: [],
    displayStatesforBetIDCreation: false,
    displayStates:[],
    IsStateValueRequired: false, 
  },
  {
    country: regionlist.IE,
    states: [],
    displayStatesforBetIDCreation: false,
    displayStates:[],
    IsStateValueRequired: false, 
  },
  {
    country: regionlist.ON,
    states: [],
    displayStatesforBetIDCreation: false,
    displayStates:[],
    IsStateValueRequired: false, 
  },
  {
    country: regionlist.GB,
    states: [],
    displayStatesforBetIDCreation: false,
    displayStates:[],
    IsStateValueRequired: false, 
  },
];

export function filterStatesByCountry(country, statesList) {
  return statesList.filter(
    (states) =>
      !stateExclusionList.filter(
        (exclude) =>
          exclude.country === country && exclude.states.includes(states.label)
      ).length
  );
}

export function filterDisplayStates(country) {
  const filteredSelections = stateExclusionList.filter(
    (item) => item.country === country
  );  
  return filteredSelections[0];
}

export const getFilteredCountryRules_Finance = (selectedCountry) => { 

  const countryfilterRules = (selectedCountryfilter) => {
    const CountryfilterValidations = [
      {
        country: regionlist.AU,        
        text: "Process All Approved & Download ABA",
        filetype:"ABA",
      },
      {
        country: regionlist.US,
        text: "Process All Approved & Download CSV",
        filetype:"CSV",
      },
      {
        country: regionlist.CA,
        text: "Process All Approved & Download CSV",
        filetype:"CSV",
      },
      {
        country: regionlist.IE,        
        text: "Process All Approved & Download CSV",
        filetype:"CSV",
      },
      {
        country: regionlist.ON,       
        text: "",
        filetype:"CSV",
      },
      {
        country: regionlist.GB,       
        text: "Process All Approved & Download CSV",
        filetype:"CSV",
      },
    ];

    const filteredRules = CountryfilterValidations.filter(
      (item) => item.country === selectedCountryfilter
    );
    return filteredRules[0]
  };

  switch (selectedCountry) {
    case regionlist.CA:
      return countryfilterRules(regionlist.CA);
    case regionlist.AU:
      return countryfilterRules(regionlist.AU);
    case regionlist.US:
      return countryfilterRules(regionlist.US);
    case regionlist.IE:
      return countryfilterRules(regionlist.IE);
    case regionlist.ON:
      return countryfilterRules(regionlist.ON);
    case regionlist.GB:
      return countryfilterRules(regionlist.GB);
    default:
      return [];
  }
};

export const withdrawalFields = {
  BANKCODE:"BANKCODE",
  SORTCODE:"SORTCODE",   
  ACCOUNTHOLDERNAME: "ACCOUNTHOLDERNAME",
  ACCOUNTHOLDERFIRSTNAME: "ACCOUNTHOLDERFIRSTNAME",
  ACCOUNTHOLDERLASTNAME: "ACCOUNTHOLDERLASTNAME",
  BRANCHTRANSITNUMBER: "BRANCHTRANSITNUMBER",
  CLIENTBSB: "CLIENTBSB",
  ACCOUNTNUMBER: "ACCOUNTNUMBER",
  AMOUNT: "AMOUNT",
  ADDRESS:"ADDRESS",
  BANKNAME:"BANKNAME",
  ACCOUNTTYPE:"ACCOUNTTYPE",
  ADDRESSLINE1:"ADDRESSLINE1",
  CITY:"CITY",
  PROVINCEORTERRITORY:"PROVINCEORTERRITORY",
  POSTALORZIPCODE:"POSTALORZIPCODE",
  COUNTRY:"COUNTRY",
  IBANNUMBER: "IBANNUMBER",
  SWIFTBIC:"SWIFTBIC"
};

export const getwithdrawalFields = (selectedCountry) => { 

  const countryfilterSelections = (selectedCountryfilter) => {
    const CountryfilterValidations = [  
      { country: regionlist.AU, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: false , text: "accHolder", validation:"", IsClientName:true },
      { country: regionlist.AU, key: withdrawalFields.ACCOUNTHOLDERFIRSTNAME,  value: false , text: "accHolderFirstName", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.ACCOUNTHOLDERLASTNAME,  value: false , text: "accHolderLastName", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.ACCOUNTTYPE,  value: false , text: "accType", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.BRANCHTRANSITNUMBER, value: false , text: "branchTransitNumber" , label: "Branch Transit Number", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.BANKCODE,  value: false , text: "financialInstitutionNumber", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.SORTCODE,  value: false , text: "sortCode", label: "Sort Code", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.CLIENTBSB, value: true , text: "clientBsb" , label: "Client's BSB" , validation:"" },
      { country: regionlist.AU, key: withdrawalFields.ACCOUNTNUMBER,  value: true , text: "accountNumber" ,  validation:[{ numeric: accountNumber_numeric, errorMessage:"Account Number should be numeric"}] },
      { country: regionlist.AU, key: withdrawalFields.AMOUNT,  value: true , text: "amount", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.BANKNAME,  value: false, text: "bankName",label: "Bank Name", validation:"" },     
      { country: regionlist.AU, key: withdrawalFields.ADDRESSLINE1, value: false, text: "addressLine1" , validation:"" },
      { country: regionlist.AU, key: withdrawalFields.CITY,  value: false , text: "city" , validation:"" },
      { country: regionlist.AU, key: withdrawalFields.PROVINCEORTERRITORY,  value: false, text: "provinceORterritory",  label: "Province/Territory ",validation:"" },
      { country: regionlist.AU, key: withdrawalFields.POSTALORZIPCODE, value: false, text: "postalORZipCode" , label: "Postal/Zip Code" ,validation:"" },
      { country: regionlist.AU, key: withdrawalFields.COUNTRY, value: false , text: "country", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.IBANNUMBER, value: false ,text: "ibanNumber",  label: "Iban", validation:"" },
      { country: regionlist.AU, key: withdrawalFields.SWIFTBIC, value: false , text: "swiftBic" , label:"Swiftbic", validation:"" },

      { country: regionlist.US, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: false , text: "accHolder", validation:"" ,IsClientName:false},
      { country: regionlist.US, key: withdrawalFields.ACCOUNTHOLDERFIRSTNAME,  value: true , text: "accHolderFirstName",label: "Account Holder's First Name", validation:[{ length:us_account_firstname_length, errorMessage:"Account Holder's First Name should be alpha character only & of length 3-45"}]  },
      { country: regionlist.US, key: withdrawalFields.ACCOUNTHOLDERLASTNAME,  value: true , text: "accHolderLastName", label: "Account Holder's Last Name",validation:[{ length:us_account_lastname_length, errorMessage:"Account Holder's Last Name should be alpha character only & of length 3-45"}]  },
      { country: regionlist.US, key: withdrawalFields.ACCOUNTTYPE,  value: true , text: "accType", validation:"", label: "Account type"  },
      { country: regionlist.US, key: withdrawalFields.BRANCHTRANSITNUMBER, value: false , text: "branchTransitNumber" , label: "Branch Transit Number", validation:"" },
      { country: regionlist.US, key: withdrawalFields.BANKCODE,  value: false , text: "financialInstitutionNumber", validation:"" },
      { country: regionlist.US, key: withdrawalFields.SORTCODE,  value: false , text: "sortCode", label: "Sort Code", validation:"" },
      { country: regionlist.US, key: withdrawalFields.CLIENTBSB, value: true , text: "clientBsb" , label: "ABA Routing Code" ,  validation:[{ numeric: us_abaRoutingCode_length, errorMessage:"ABA Routing Code should be numeric & of length 9"}] },
      { country: regionlist.US, key: withdrawalFields.ACCOUNTNUMBER,  value: true , text: "accountNumber"  , validation:[{ numeric: us_accountNumber_length, errorMessage:"Account Number should be numeric & of length 2-17"}] },
      { country: regionlist.US, key: withdrawalFields.AMOUNT,  value: true, text: "amount", validation:"" },
      { country: regionlist.US, key: withdrawalFields.BANKNAME,  value: true, text: "bankName",label: "Bank Name", validation:[{ length:us_account_bankname_length, errorMessage:"Bank Name should be alpha character only & of length 3-45"}]  },      
      { country: regionlist.US, key: withdrawalFields.ADDRESSLINE1, value: true, text: "addressLine1" , validation:"" },
      { country: regionlist.US, key: withdrawalFields.CITY,  value: true , text: "city" , validation:"" },
      { country: regionlist.US, key: withdrawalFields.PROVINCEORTERRITORY,  value: true, text: "provinceORterritory", label: "State", validation:"" },
      { country: regionlist.US, key: withdrawalFields.POSTALORZIPCODE, value: true , label: "ZIP Code",text: "postalORZipCode" , validation:[{ alphanumeric: us_postcode, errorMessage:"Postal/ZipCode should be alphanumeric & should be of length 5-10"}] }, 
      { country: regionlist.US, key: withdrawalFields.COUNTRY, value: true,text: "country" , validation:"" },
      { country: regionlist.US, key: withdrawalFields.IBANNUMBER, value: false ,text: "ibanNumber",  label: "Iban", validation:"" },
      { country: regionlist.US, key: withdrawalFields.SWIFTBIC, value: false , text: "swiftBic" , label:"Swiftbic", validation:"" },

      { country: regionlist.CA, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: true , text: "accHolder",  label: "Account Holder's Name", validation:"", IsClientName:false }, 
      { country: regionlist.CA, key: withdrawalFields.ACCOUNTHOLDERFIRSTNAME,  value: false , text: "accHolderFirstName", validation:"" },
      { country: regionlist.CA, key: withdrawalFields.ACCOUNTHOLDERLASTNAME,  value: false , text: "accHolderLastName", validation:"" },
      { country: regionlist.CA, key: withdrawalFields.ACCOUNTTYPE,  value: false , text: "accType", validation:"" },     
      { country: regionlist.CA, key: withdrawalFields.BRANCHTRANSITNUMBER, value: true , text: "branchTransitNumber" , label: "Branch Transit Number", validation:[{ numeric: accountNumber_numeric,  errorMessage:"Branch Transit Number should be numeric"},{length:branchTransitNumber_length, errorMessage:"Branch Transit Number should be of minimum length 5"}] },      
      { country: regionlist.CA, key: withdrawalFields.BANKCODE,  value: true , text: "financialInstitutionNumber", label: "Bank Code", validation:[{ numeric: bankcode_ca, errorMessage:"Bank Code should be numeric & should be of length 3"}] },
      { country: regionlist.CA, key: withdrawalFields.SORTCODE,  value: false , text: "sortCode", label: "Sort Code", validation:"" },
      { country: regionlist.CA, key: withdrawalFields.CLIENTBSB, value: false , text: "clientBsb" , label: "Client's BSB" , validation:"" },      
      { country: regionlist.CA, key: withdrawalFields.ACCOUNTNUMBER,  value: true , text: "accountNumber" , validation:[{ numeric: accountNumber_numeric,  errorMessage:"Account Number should be numeric"}, {length:accountNumber_ca_length, errorMessage:"Account Number should be of length 5-12"}] },
      { country: regionlist.CA, key: withdrawalFields.AMOUNT,  value: true, text: "amount", validation:"" },
      { country: regionlist.CA, key: withdrawalFields.BANKNAME,  value: true,label: "Bank Name", text: "bankName", validation:"" },      
      { country: regionlist.CA, key: withdrawalFields.ADDRESSLINE1, value: true, text: "addressLine1" , validation:"" },
      { country: regionlist.CA, key: withdrawalFields.CITY,  value: true , text: "city" , validation:"" },
      { country: regionlist.CA, key: withdrawalFields.PROVINCEORTERRITORY,  value: true, text: "provinceORterritory", label: "Province/Territory ",validation:"" },
      { country: regionlist.CA, key: withdrawalFields.POSTALORZIPCODE, value: true , label: "Postal/Zip Code" ,text: "postalORZipCode" , validation:[{ alphanumeric: postcode_ca, errorMessage:"Postal/ZipCode should be alphanumeric & should be of length 6-10"}] }, 
      { country: regionlist.CA, key: withdrawalFields.COUNTRY, value: true,text: "country" , validation:"" },
      { country: regionlist.CA, key: withdrawalFields.IBANNUMBER, value: false ,text: "ibanNumber",  label: "Iban", validation:"" },
      { country: regionlist.CA, key: withdrawalFields.SWIFTBIC, value: false , text: "swiftBic" , label:"Swiftbic", validation:"" },

      { country: regionlist.IE, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: false , text: "accHolder", validation:"" , IsClientName:false},
      { country: regionlist.IE, key: withdrawalFields.ACCOUNTHOLDERFIRSTNAME,  value: true , text: "accHolderFirstName", label: "Account Holder's First Name",validation:[{ length:ie_account_firstname_length, errorMessage:"Account Holder's First Name should be alpha character only & of length 3-50"}]  },
      { country: regionlist.IE, key: withdrawalFields.ACCOUNTHOLDERLASTNAME,  value: true , text: "accHolderLastName",label: "Account Holder's Last Name", validation:[{ length:ie_account_lastname_length, errorMessage:"Account Holder's Last Name should be alpha character only & of length 3-50"}]  },
      { country: regionlist.IE, key: withdrawalFields.ACCOUNTTYPE,  value: false , text: "accType", validation:"" }, 
      { country: regionlist.IE, key: withdrawalFields.BRANCHTRANSITNUMBER, value: false , text: "branchTransitNumber" , label: "Branch Transit Number", validation:"" },
      { country: regionlist.IE, key: withdrawalFields.BANKCODE,  value: false , text: "financialInstitutionNumber", label: "Bank Code", validation:[{ numeric: bankcode_ca, errorMessage:"Bank Code should be numeric & should be of length 3"}] },
      { country: regionlist.IE, key: withdrawalFields.SORTCODE,  value: false , text: "sortCode", label: "Sort Code", validation:"" },
      { country: regionlist.IE, key: withdrawalFields.CLIENTBSB, value: false , text: "clientBsb" , validation:"" },
      { country: regionlist.IE, key: withdrawalFields.ACCOUNTNUMBER,  value: false , text: "accountNumber" , validation:"" },
      { country: regionlist.IE, key: withdrawalFields.AMOUNT,  value: true, text: "amount", validation:"" },
      { country: regionlist.IE, key: withdrawalFields.BANKNAME,  value: true, text: "bankName",label: "Bank Name", validation:[{ length:ie_account_bankname_length, errorMessage:"Bank Name should be alpha character only & of length 3-50"}]  },
      { country: regionlist.IE, key: withdrawalFields.ADDRESSLINE1, value: false, text: "addressLine1" , validation:"" },
      { country: regionlist.IE, key: withdrawalFields.CITY,  value: false , text: "city" , validation:"" },
      { country: regionlist.IE, key: withdrawalFields.PROVINCEORTERRITORY,  value: false, text: "provinceORterritory", validation:"" },
      { country: regionlist.IE, key: withdrawalFields.POSTALORZIPCODE, value: false , text: "postalORZipCode" , validation:"" },
      { country: regionlist.IE, key: withdrawalFields.COUNTRY, value: false ,text: "country", validation:"" },
      { country: regionlist.IE, key: withdrawalFields.IBANNUMBER, value: true ,text: "ibanNumber",  label: "IBAN",  validation:[{ length: ie_account_iban, errorMessage:"Account Number should be numeric & of length 22"}] },
      { country: regionlist.IE, key: withdrawalFields.SWIFTBIC, value: true , text: "swiftBic" , label:"SWIFT BIC", validation:[{ length: ie_account_swiftBic, errorMessage:"The SWIFT BIC should be alphanumeric & should be of length 8-11"}] },

      { country: regionlist.ON, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: false , text: "accHolder", validation:"",IsClientName:false },
      { country: regionlist.ON, key: withdrawalFields.ACCOUNTTYPE,  value: false , text: "accType", validation:"" }, 
      { country: regionlist.ON, key: withdrawalFields.BRANCHTRANSITNUMBER, value: false , text: "branchTransitNumber" , label: "Branch Transit Number", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.BANKCODE,  value: false , text: "financialInstitutionNumber", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.SORTCODE,  value: false , text: "sortCode", label: "Sort Code", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.CLIENTBSB, value: true , text: "clientBsb" , validation:"" },
      { country: regionlist.ON, key: withdrawalFields.ACCOUNTNUMBER,  value: true , text: "accountNumber" , validation:"" },
      { country: regionlist.ON, key: withdrawalFields.AMOUNT,  value: true, text: "amount", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.BANKNAME,  value: false, text: "bankName", validation:"" },      
      { country: regionlist.ON, key: withdrawalFields.ADDRESSLINE1, value: false, text: "addressLine1" , validation:"" },
      { country: regionlist.ON, key: withdrawalFields.CITY,  value: false , text: "city" , validation:"" },
      { country: regionlist.ON, key: withdrawalFields.PROVINCEORTERRITORY,  value: false, text: "provinceORterritory", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.POSTALORZIPCODE, value: false , text: "postalORZipCode" , validation:"" },
      { country: regionlist.ON, key: withdrawalFields.COUNTRY, value: false ,text: "country", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.IBANNUMBER, value: false ,text: "ibanNumber",  label: "Iban", validation:"" },
      { country: regionlist.ON, key: withdrawalFields.SWIFTBIC, value: false , text: "swiftBic" , label:"Swiftbic", validation:"" },

      { country: regionlist.GB, key: withdrawalFields.ACCOUNTHOLDERNAME,  value: false , text: "accHolder", validation:"" ,IsClientName:false},
      { country: regionlist.GB, key: withdrawalFields.ACCOUNTHOLDERFIRSTNAME,  value: true , text: "accHolderFirstName",label: "Account Holder's First Name", validation:[{ length:uk_account_firstname_length, errorMessage:"Account Holder's First Name should be alpha character only & of length 3-50"}]  },
      { country: regionlist.GB, key: withdrawalFields.ACCOUNTHOLDERLASTNAME,  value: true , text: "accHolderLastName", label: "Account Holder's Last Name",validation:[{ length:uk_account_lastname_length, errorMessage:"Account Holder's Last Name should be alpha character only & of length 3-50"}]  },
      { country: regionlist.GB, key: withdrawalFields.ACCOUNTTYPE,  value: false , text: "accType", validation:"", label: "Account type"  },
      { country: regionlist.GB, key: withdrawalFields.BRANCHTRANSITNUMBER, value: false , text: "branchTransitNumber" , label: "Branch Transit Number", validation:"" },
      { country: regionlist.GB, key: withdrawalFields.BANKCODE,  value: false , text: "financialInstitutionNumber", label: "Sort Code", validation:[{ numeric: uk_sortCode_length, errorMessage:"Sort Code should be numeric & of length 6"}] },
      { country: regionlist.GB, key: withdrawalFields.SORTCODE,  value: true , text: "sortCode", label: "Sort Code", validation:[{ numeric: uk_sortCode_length, errorMessage:"Sort Code should be numeric & of length 6"}] },
      { country: regionlist.GB, key: withdrawalFields.CLIENTBSB, value: false , text: "clientBsb" , label: "BSB Code" ,  validation:[{ numeric: uk_abaRoutingCode_length, errorMessage:"BSB Code should be numeric & of length 6"}] },
      { country: regionlist.GB, key: withdrawalFields.ACCOUNTNUMBER,  value: true , text: "accountNumber"  , validation:[{ numeric: uk_accountNumber_length, errorMessage:"Account Number should be numeric & of length 8"}] },
      { country: regionlist.GB, key: withdrawalFields.AMOUNT,  value: true, text: "amount", validation:[{ numeric: uk_amount_length, errorMessage:"Amount should be numeric"}] },
      { country: regionlist.GB, key: withdrawalFields.BANKNAME,  value: true, text: "bankName",label: "Bank Name", validation:[{ length:uk_account_bankname_length, errorMessage:"Bank Name should be aphanumeric only & of length 3-50"}]  },      
      { country: regionlist.GB, key: withdrawalFields.ADDRESSLINE1, value: false, text: "addressLine1" , validation:"" },
      { country: regionlist.GB, key: withdrawalFields.CITY,  value: false , text: "city" , validation:"" },
      { country: regionlist.GB, key: withdrawalFields.PROVINCEORTERRITORY,  value: false, text: "provinceORterritory", label: "State", validation:"" },
      { country: regionlist.GB, key: withdrawalFields.POSTALORZIPCODE, value: false , label: "ZIP Code",text: "postalORZipCode" , validation:[{ alphanumeric: us_postcode, errorMessage:"Postal/ZipCode should be alphanumeric & should be of length 5-10"}] }, 
      { country: regionlist.GB, key: withdrawalFields.COUNTRY, value: false,text: "country" , validation:"" },
      { country: regionlist.GB, key: withdrawalFields.IBANNUMBER, value: false ,text: "ibanNumber",  label: "Iban", validation:"" },
      { country: regionlist.GB, key: withdrawalFields.SWIFTBIC, value: true , text: "swiftBic" , label:"SWIFT BIC", validation:[{ length: ie_account_swiftBic, errorMessage:"The SWIFT BIC should be alphanumeric & should be of length 8-11"}] },
    ];

    const filteredSelections = CountryfilterValidations.filter(
      (item) => item.country === selectedCountryfilter
    );

    return filteredSelections;
  };

  switch (selectedCountry) {
    case regionlist.CA:
      return countryfilterSelections(regionlist.CA);
    case regionlist.AU:
      return countryfilterSelections(regionlist.AU);
    case regionlist.US:
      return countryfilterSelections(regionlist.US);
    case regionlist.IE:
      return countryfilterSelections(regionlist.IE);
    case regionlist.ON:
      return countryfilterSelections(regionlist.ON);
    case regionlist.GB:
      return countryfilterSelections(regionlist.GB);
    default:
      return [];
  }
};

export const updateCurrencyCode = (selectedCountry) => { 

  const countryfilterRules = (selectedCountryfilter) => {
    const CountryfilterValidations = [
      {
        country: regionlist.AU,        
        currencyCode: "AUD", 
        currencySymbol: "$",        
      },
      {
        country: regionlist.US,
        currencyCode: "USD", 
        currencySymbol: "$",  
      },
      {
        country: regionlist.CA,        
        currencyCode: "CAD", 
        currencySymbol: "$",   
      },
      {
        country: regionlist.IE,        
        currencyCode: "EUR", 
        currencySymbol: "€", 
      },
      {
        country: regionlist.ON,       
        currencyCode: "CAD",  
        currencySymbol: "$",  
      },
      {
        country: regionlist.GB,       
        currencyCode: "GBP",  
        currencySymbol: "£",
      },
    ];

    const filteredRules = CountryfilterValidations.filter(
      (item) => item.country === selectedCountryfilter
    );
    localStorage.setItem('currencyCode', filteredRules[0].currencyCode);
    localStorage.setItem('currencySymbol', filteredRules[0].currencySymbol);
  };

  switch (selectedCountry) {
    case regionlist.CA:
      return countryfilterRules(regionlist.CA);
    case regionlist.AU:
      return countryfilterRules(regionlist.AU);
    case regionlist.US:
      return countryfilterRules(regionlist.US);
    case regionlist.IE:
      return countryfilterRules(regionlist.IE);
    case regionlist.ON:
      return countryfilterRules(regionlist.ON);
    case regionlist.GB:
        return countryfilterRules(regionlist.GB);
    default:
      return [];
  }
};

export const fieldDetails = {
  ENTRY_AMOUNT: "ENTRY AMOUNT",
  VALUE_AFTER_COMMISION: "VALUE AFTER COMMISION",
  VIEW_COUNT: "View Count",
  WINNING_SHARE_AFTER_RESULT: "Winning Share After Result",
  COMMISION: "Commisions",
  TOTAL_ENTRIES: "Total Entries",
  WINNING_ENTRIES: "Winning Entries",
  WIN_AMOUNT: "Win Amount",
  BET_AMOUNT: "Bet Amount",

};

export const getBetTypeSelection = (betType) => {
  const betfilterSelections = (betTypefilter) => {
    const BettypefilterValidations = [     
      { type: betTypelist.Jackpot, key: fieldDetails.ENTRY_AMOUNT, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.VALUE_AFTER_COMMISION, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.COMMISION, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.TOTAL_ENTRIES, value: true },
      { type: betTypelist.Jackpot, key: fieldDetails.WINNING_ENTRIES, value: true },     
      { type: betTypelist.Jackpot, key: fieldDetails.BET_AMOUNT, value: false },     
      { type: betTypelist.Jackpot, key: fieldDetails.WIN_AMOUNT, value: false },     

      { type: betTypelist.Golf, key: fieldDetails.ENTRY_AMOUNT, value: false },
      { type: betTypelist.Golf, key: fieldDetails.VALUE_AFTER_COMMISION, value: false },
      { type: betTypelist.Golf, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.Golf, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: false },
      { type: betTypelist.Golf, key: fieldDetails.COMMISION, value: false },
      { type: betTypelist.Golf, key: fieldDetails.TOTAL_ENTRIES, value: false },
      { type: betTypelist.Golf, key: fieldDetails.WINNING_ENTRIES, value: false },
      { type: betTypelist.Golf, key: fieldDetails.BET_AMOUNT, value: true },     
      { type: betTypelist.Golf, key: fieldDetails.WIN_AMOUNT, value: true },  
     
      { type: betTypelist.TippingComp, key: fieldDetails.ENTRY_AMOUNT, value: false },
      { type: betTypelist.TippingComp, key: fieldDetails.VALUE_AFTER_COMMISION, value: false },
      { type: betTypelist.TippingComp, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.TippingComp, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: false },
      { type: betTypelist.TippingComp, key: fieldDetails.COMMISION, value: false },
      { type: betTypelist.TippingComp, key: fieldDetails.TOTAL_ENTRIES, value: false },
      { type: betTypelist.TippingComp, key: fieldDetails.WINNING_ENTRIES, value: false },     
      { type: betTypelist.TippingComp, key: fieldDetails.BET_AMOUNT, value: true },     
      { type: betTypelist.TippingComp, key: fieldDetails.WIN_AMOUNT, value: true },  

      { type: betTypelist.ConsoleGame, key: fieldDetails.ENTRY_AMOUNT, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.VALUE_AFTER_COMMISION, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.ConsoleGame, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.COMMISION, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.TOTAL_ENTRIES, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.WINNING_ENTRIES, value: false },
      { type: betTypelist.ConsoleGame, key: fieldDetails.BET_AMOUNT, value: true },     
      { type: betTypelist.ConsoleGame, key: fieldDetails.WIN_AMOUNT, value: true },  

      { type: betTypelist.Cash, key: fieldDetails.ENTRY_AMOUNT, value: false },
      { type: betTypelist.Cash, key: fieldDetails.VALUE_AFTER_COMMISION, value: false },
      { type: betTypelist.Cash, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.Cash, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: false },
      { type: betTypelist.Cash, key: fieldDetails.COMMISION, value: false },
      { type: betTypelist.Cash, key: fieldDetails.TOTAL_ENTRIES, value: false },
      { type: betTypelist.Cash, key: fieldDetails.WINNING_ENTRIES, value: false },
      { type: betTypelist.Cash, key: fieldDetails.BET_AMOUNT, value: true },     
      { type: betTypelist.Cash, key: fieldDetails.WIN_AMOUNT, value: true },  

      { type: betTypelist.Handshake, key: fieldDetails.ENTRY_AMOUNT, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.VALUE_AFTER_COMMISION, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.VIEW_COUNT, value: true },
      { type: betTypelist.Handshake, key: fieldDetails.WINNING_SHARE_AFTER_RESULT, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.COMMISION, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.TOTAL_ENTRIES, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.WINNING_ENTRIES, value: false },
      { type: betTypelist.Handshake, key: fieldDetails.BET_AMOUNT, value: true },     
      { type: betTypelist.Handshake, key: fieldDetails.WIN_AMOUNT, value: true },  
    ];

    const filteredSelections = BettypefilterValidations.filter(
      (item) => item.type === betTypefilter
    );
    return filteredSelections;
  };

  switch (betType) {
    case "Jackpot":
      return betfilterSelections("Jackpot");
    case "TippingComp":
      return betfilterSelections("TippingComp");
    case "Golf":
      return betfilterSelections("Golf");
    case "ConsoleGame":
      return betfilterSelections("ConsoleGame");
    case "Cash":
      return betfilterSelections("Cash");
    case "Handshake":
      return betfilterSelections("Handshake");
    default:
      return [];
  }
};