const initState = {
    parentCategoryData: {},
    parentCategoryDataById : {}
};

const parentCategoryReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case "GET_PARENT_CATEGORY_DATA":
            return { ...state, parentCategoryData: payload };
        case "GET_PARENT_CATEGORY_BY_ID" : 
            return {...state, parentCategoryDataById: payload }
        default:
            return state;
    }
};

export default parentCategoryReducer;