import {fetchPost} from "../commons";
import {toastr} from "react-redux-toastr";

const requestValidateClientIds = 'REQUEST_VALIDATE_CLIENT_IDS';
const responseValidateClientIds = 'RESPONSE_VALIDATE_CLIENT_IDS';
const requestRewardSelectedClients = 'REQUEST_REWARD_SELECTED';
const responseRewardSelectedClients = 'RESPONSE_REWARD_SELECTED';
const requestRewardAllActiveClients = 'REQUEST_REWARD_ALL_CLIENTS';
const responseRewardAllActiveClients = 'RESPONSE_REWARD_ALL_CLIENTS';

const initialState = {
  requestingClientIdsValidation: false,
  validationResult: [],
  rewardingSelected: false,
  rewardingSelectedResponse: null,
  rewardingAll: false,
  rewardingAllResponse: null,
};

export const actionCreators = {
  requestClientIdsValidation: (clientIds) => async (dispatch, getState) => {
    dispatch({type: requestValidateClientIds});
    const [err, response] = await fetchPost('/bonus/validate-clients', {
      ClientIds: clientIds.filter(i => i > 0)
    });
    dispatch({type: responseValidateClientIds, response});
  },
  rewardSelectedClients: (amount, expiryDate, clientIds, callback) => async (dispatch, getState) => {
    dispatch({type: requestRewardSelectedClients});
    const [err, response] = await fetchPost('/bonus/reward', {
      ClientIds: clientIds.filter(i => i > 0),
      Amount: amount,
      ExpiryDate: expiryDate,
      AllActiveClients: false
    });
    if (!!callback) dispatch(callback);
    dispatch({type: responseRewardSelectedClients, response});
  },
  rewardAllActiveClients: (amount, expiryDate) => async (dispatch, getState) => {
    dispatch({type: requestRewardAllActiveClients});
    const [err, response] = await fetchPost('/bonus/reward-all', {
      Amount: amount,
      ExpiryDate: expiryDate,
      AllActiveClients: true
    });
    let errorMessage = err ? err : !response ? 'Unknown error' : response.errorNo > 0 ? response.message : null;

    if (errorMessage) toastr.error(errorMessage);
    else toastr.success(response.message);
    dispatch({type: responseRewardAllActiveClients, response});
  }
};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestValidateClientIds:
      return {...state, requestingClientIdsValidation: true};
    case responseValidateClientIds:
      return {...state, requestingClientIdsValidation: false, validationResult: action.response};
    case requestRewardSelectedClients:
      return {...state, rewardingSelected: true};
    case responseRewardSelectedClients:
      return {...state, rewardingSelectedResponse: action.response, rewardingSelected:false};
    case requestRewardAllActiveClients:
      return {...state, rewardingAll: true};
    case responseRewardAllActiveClients:
      return {...state, rewardingAll: false, rewardingAllResponse: action.response};
    default:
      return state;
  }
};
