import { fetchGet, fetchPost } from "../../../commons";
import { toastr } from "react-redux-toastr";
import { actionCreators as chatActionCreators } from "../../Chats";

export const requestPendingBets = "REQUEST_PENDING_BETS";
export const requestSupervisorBets = "REQUEST_SUPERVISOR_BETS";
export const requestReportedBets = "REQUEST_REPORTED_BETS";
export const requestIndividualBetDetails = "REQUEST_INDIVIDUAL_BET_DETAILS";
export const requestLReasons = "REQUEST_LReasons";
export const requestCancelReasons = "REQUEST_CANCEL_Reasons";
export const requestUpdateBetCloseDate = "REQUEST_UPDATE_BET_CLOSE_DATE";
export const requestUpdateBetFinishDate = "REQUEST_UPDATE_BET_FINISH_DATE";

const initialState = {
  pendingBets: null,
  supervisorBets: null,
  reportedBets: null,
  individualBetDetails: null,
  updatingDate: false,
  updatingFinishDate: false,
  lReasons: null,
  cancelReasons: null,

};

export const actionCreators = {
  getPendingBets: (filter) => async (dispatch) => {
    var postFilter = {
      ...filter,
      dateFilterOn: filter.dateFilterOn.find((d) => d.checked).key,
      types: filter.types.filter((t) => t.checked),
      BetCurrencies: [],
    };
    const [error, response] = await fetchPost("/bets/pending", {
      ...postFilter,
    });

    dispatch({ type: requestPendingBets, response });
  },

  getSupervisorBets: (filter) => async (dispatch) => {
    var postFilter = {
      ...filter,
      dateFilterOn:
        filter &&
        filter.dateFilterOn &&
        filter.dateFilterOn.find((d) => d.checked).key,
      types: filter && filter.types && filter.types.filter((t) => t.checked),
      BetCurrencies:
        filter &&
        filter.BetCurrencies &&
        filter.BetCurrencies.filter((t) => t.checked),
    };
    const [error, response] = await fetchPost("/bets/getsupervisorbetdata", {
      ...postFilter,
    });

    dispatch({ type: requestSupervisorBets, response });
  },

  getReportedBets: (filter) => async (dispatch) => {
    var postFilter = {
      ...filter,
      types: filter.types.filter((t) => t.checked),
      BetCurrencies: filter.BetCurrencies.filter((t) => t.checked),
    };
    const [error, response] = await fetchPost("/bets/reported", {
      ...postFilter,
    });
    dispatch({ type: requestReportedBets, response });
  },

  postAdminActionReportedBet:
    (
      moderationRequestId,
      newStatus,
      privateNote,
      sendPushNotification,
      overrideNotificationText,
      cb = () => {}
    ) =>
    async (dispatch) => {
      const [, response] = await fetchPost("/moderation-request/action", {
        moderationRequestId,
        newStatus,
        privateNote,
        sendPushNotification,
        overrideNotificationText,
      });
      if (response.errorNo === 0) {
        toastr.success(`Action ${newStatus} is successfully executed`);
        if (cb) {
          cb();
        }
      } else {
        toastr.error(`Action ${newStatus} failed to execute`);
      }
    },

  getBet: (betId) => async (dispatch) => {
    const [, response] = await fetchGet(`/bet/${betId}`);
    dispatch({ type: requestIndividualBetDetails, response });
    if (response && response.chatId) {
      dispatch(chatActionCreators.getBetsChatMessage(response.chatId));
    }
  },
  // get reject bet reason
  getLReasons: () => async (dispatch) => {
    const [, response] = await fetchPost("/lreasons", { lreasonType: "7" });
    dispatch({ type: requestLReasons, response });
  },

  // get cancel bet reason
  getCancelReasons: () => async (dispatch) => {
    const [, response] = await fetchPost("/lreasons", { lreasonType: "8" });
    dispatch({ type: requestCancelReasons, response });
  },

  handleBetApproval:
    (
      betId,
      statement,
      amount,
      requestedAction,
      reason,
      comment,
      callBack,
      isSupervisorBet,
      cb = () => {}
    ) =>
    async (dispatch) => {
      const [error, response] = await fetchPost("/bet/status/update", {
        betIds: [betId],
        command: requestedAction,
        fullStatement: statement,
        amount: amount,
        reason: reason,
        comments: comment,
        IsSupervisorBet: isSupervisorBet === "supervisorBet" ? true : null,
      });
      if (response.errorNo === 0) {
        if (cb && isSupervisorBet === "supervisorBet") {
          cb();
        }
        requestedAction === "Open"
          ? toastr.success("Bet approved successfully.")
          : toastr.success("Bet rejected successfully.");
      } else {
        toastr.error(
          response.message ? response.message : "Something went wrong"
        );
      }
      dispatch(callBack);
    },

  handleBetCancel:
    (
      betId,
      comment,
      reason,
      isSupervisorBet,
      cb = () => {},
      currentBetStatus
    ) =>
    async (dispatch) => {
      const [error, response] = await fetchPost(
        `/bet/${currentBetStatus === "Resulted" ? "result" : "status"}/update`,
        {
          betIds: [betId],
          command:
            currentBetStatus === "Resulted"
              ? "Resulted"
              : currentBetStatus === "PendingApproval"
              ? "RejectedApproval"
              : "Cancelled",
          comments: comment,
          reason: reason,
          IsSupervisorBet: isSupervisorBet === "supervisorBet" ? true : null,
        }
      );
      if (response.errorNo === 0) {
        if (cb && isSupervisorBet === "supervisorBet") {
          cb();
        }
        toastr.success("Bet cancelled successfully.");
      } else {
        toastr.error("Bet cancelled unsuccessful.");
      }
      dispatch(actionCreators.getBet(betId));
    },

  handleBetSettlement:
    (
      betId,
      flagResultAsCorrectStatement,
      reason,
      comment,
      fullStatement,
      amount,
      callBack,
      isSupervisorBet
    ) =>
    async (dispatch) => {
      const [error, response] = await fetchPost("/bet/status/update", {
        betIds: [betId],
        command: "Resulted",
        flagResultAsCorrectStatement: flagResultAsCorrectStatement,
        reason: reason,
        comments: comment,
        fullStatement: fullStatement,
        amount: amount,
        IsSupervisorBet: isSupervisorBet === "supervisorBet" ? true : null,
      });

      if (response.errorNo === 0) {
        toastr.success("Settled Successfully");
      } else if (response.errorNo === 5018) {
        toastr.info(response.message);
      } else {
        toastr.error("Settled Unsuccessful", response.message);
      }

      dispatch(callBack);
    },

  setBetVisibility: (betId, visibility, callBack) => async (dispatch) => {
    const [, response] = await fetchPost(
      `/bet/${betId}/visibility/${visibility}`,
      {}
    );
    if (response.length > 0) {
      toastr.error("Bet visibility update failed.");
    } else {
      toastr.success("Bet visibility updated.");
    }
    dispatch(callBack);
  },

  updateBetCloseDate: (betId, closeDate) => async (dispatch) => {
    dispatch({ type: requestUpdateBetCloseDate });

    const [, response] = await fetchPost("/bet/closeDate/update", {
      betId: betId,
      betCloseDate: closeDate,
    });
    if (response.errorNo === 0) {
      response.message
        ? toastr.info(response.message)
        : toastr.success("Bet close date updated successfully");
    } else {
      toastr.error(response.message);
    }
    dispatch(actionCreators.getBet(betId));
  },

  // TODO: update bet finish date function
  updateBetFinishDate:
    (betId, sportId, sportType, betFinishDate) => async (dispatch) => {
      dispatch({ type: requestUpdateBetFinishDate });

      const [, response] = await fetchPost("/sport/update-finish-date", {
        SportId: sportId,
        SportType: sportType,
        FinishDate: betFinishDate,
      });
      if (response.errorNo === 0) {
        toastr.success(response.message);
      } else {
        toastr.error(response.message);
      }
      dispatch(actionCreators.getBet(betId));
    },

  handleConvertToHandshake:
    (betId, isSupervisorBet, comment, reason, cb = () => {}) =>
    async (dispatch) => {
      const [, response] = await fetchPost("/bets/converttohandshake", {
        betId: betId,
        IsSupervisorBet: isSupervisorBet ? true : null,
        reason: reason,
        comments: comment,
      });
      if (response.errorNo === 0) {
        if (cb && isSupervisorBet) {
          cb();
        }
        toastr.success("Bet converted to handshake Successfully.");
      } else {
        toastr.error(response.message);
      }
      dispatch(actionCreators.getBet(betId));
    },

  handleSendToSupervisor:
    (betId, cb = () => {}) =>
    async (dispatch) => {
      const [, response] = await fetchPost("/bets/sendtosupervisor", {
        betId: betId,
      });
      if (response.betId || response.errorNo === 0) {
        toastr.success("Bet sent to supervisor Successfully.");
        if (cb) {
          cb();
        }
      } else {
        toastr.error(response.message);
      }
      dispatch(actionCreators.getBet(betId));
    },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestPendingBets:
      return { ...state, pendingBets: action.response };
    case requestSupervisorBets:
      return { ...state, supervisorBets: action.response };
    case requestReportedBets:
      return { ...state, reportedBets: action.response };
    case requestIndividualBetDetails:
      return {
        ...state,
        updatingDate: false,
        updatingFinishDate: false,
        individualBetDetails: action.response,
      };
    case requestLReasons:
      return { ...state, lReasons: action.response };
    case requestCancelReasons:
      return { ...state, cancelReasons: action.response };
    case requestUpdateBetCloseDate:
      return { ...state, updatingDate: true };
    case requestUpdateBetFinishDate:
      return { ...state, updatingFinishDate: true };
    default:
      return state;
  }
};
