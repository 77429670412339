// import internal(own) modules

const initState = {
  userData: null,
  isLogging: false,
};

const updateCoutryNames = (payload) => { 
  let countryExclusionList = {
    ON:"ON", //Ontario
    CO:"CO", //Colorado
  };
  const ccList = payload.ccList;
  const regionlist = ccList.map((values) => {
      return {
      [values.split(':')[0]]: values.split(':')[0]     
    }; 
  });
  const countryFilters_All = ccList.map((values) => {      
    return {
      label: values.split(':')[0],
      value: values.split(':')[1],
    };    
  });  

  const countryFilters_AddcountryExclusions = ccList
    .filter((country) =>
      Object.keys(countryExclusionList).every(
        (item) => countryExclusionList[item] !== country.split(":")[0]
      )
    )
    .map((values) => {
      return {
        label: values.split(":")[0],
        value: values.split(":")[1],
      };
    });


  return {regionlist,countryFilters_AddcountryExclusions,countryFilters_All};  
};


const usersReducer = (state = initState, {type, payload}) => {
  switch (type) {
    case "AUTHENTICATE_BEGIN":
      return {...state, isLogging: true};
    case "AUTHENTICATE_FAILURE":
      return {...state, isLogging: false};
    case "AUTHENTICATE_SUCCESS":
    case "GET_LOCAL_USER_SUCCESS":
      const ccList= updateCoutryNames(payload);
      return {...state, isLogging: false, userData: {...payload,regionlist:ccList.regionlist,countryFilters:ccList.countryFilters_AddcountryExclusions,countryFilters_CMS:ccList.countryFilters_All}};
    case "FORGOT_PASSWORD_SUCCESS":
      return {...state, userData: payload};
    case "RESET_PASSWORD_SUCCESS":
      return {...state, userData: payload};
    case "RESET_PASSWORD_STEP2_SUCCESS":
      return {...state, userData: payload};
    case "UPDATE_PASSWORD_SUCCESS":
      return {...state, userData: payload};
    case "GET_USERS_SUCCESS":
      return {...state, isLogging: false, usersData: payload ? payload : state.usersData};
    default:
      return state;
  }
};

export default usersReducer;
