const initialState = {
  broadcastMessages: [],
  loading: false,
  error: "",
  success: "",
  broadcastGroupsList: [],
  isNotificationSending: false,
};

export const getbroadcastMessages = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "IS_LOADING_BROADCAST":
      return { loading: true, ...state, broadcastMessages: null };
    case "IS_NOTIFICATION_SENDING":
      return { ...state, isNotificationSending: true,error: payload};
    case "GETNOTIFICATIONLIST_SUCCESS":
      return { loading: false, ...state, broadcastMessages: payload };
    case "GETSENDBROADCAST_ERROR":
      return { ...state,isNotificationSending: false, error: payload };
    case "GETSENDBROADCAST_SUCCESS":
      return { ...state,isNotificationSending: false, success: payload };
    case "GETBROADCASTGROUPLIST_SUCCESS":
      return { ...state, ...payload };
    case "GETBROADCASTGROUPLIST_ERROR":
      return { ...state, ...payload };
    default:
      return state;
  }
};
