import {fetchGet, fetchPost} from "../commons";
import {toastr} from "react-redux-toastr";

export const requestSystemSettings = "REQUEST_SYSTEM_SETTINGS";
export const responseSystemSettings = "RESPONSE_SYSTEM_SETTINGS";
export const requestUpdateSystemSettings = "REQUEST_UPDATE_SYSTEM_SETTINGS";
export const responseUpdateSystemSettings = "RESPONSE_UPDATE_SYSTEM_SETTINGS";
export const requestCommissionSettings = "REQUEST_COMMISSION_SETTINGS";
export const responseCommissionSettings = "RESPONSE_COMMISSION_SETTINGS";
export const requestBannedWords = "REQUEST_BANNED_WORDS";
export const responseBannedWords = "RESPONSE_BANNED_WORDS";
export const requestUpdateBannedWords = "REQUEST_UPDATE_BANNED_WORDS";
export const responseUpdateBannedWords = "RESPONSE_UPDATE_BANNED_WORDS";
export const responseSlabBetSettings = "RESPONSE_SLAB_BET_SETTINGS";
export const responseUpdateSlabBetSettings = "RESPONSE_UPDATE_SLAB_BET_SETTINGS";
export const requestCountryList = 'REQUEST_COUNTRIES_LIST';
export const responseCountryList = 'RESPONSE_COUNTRIES_LIST';
export const requestAddCountryIntoList = 'REQUEST_ADD_COUNTRIES_INTO_LIST';
export const responseAddCountryIntoList = 'RESPONSE_ADD_COUNTRIES_INTO_LIST';


const initialState = {
  loadingSystemSettings: false,
  systemSettings: null,
  updatingSystemSettings: false,
  loadingCommissionSettings: false,
  commissionSettings: null,
  loadingBannedWords: false,
  bannedWords: [],
  updatingBannedWords: false,
  slabBetSettings: false,
  profileStats : {profileCountriesData : {countries : [],systemConfigs:[]},isLoadingProfileStats : false}
};

export const actionCreators = {
  getSystemSettings: (id) => async (dispatch, getState) => {
    dispatch({type: requestSystemSettings});
    const [, response] = await fetchGet(`/settings/config/${id}`);
    dispatch({type: responseSystemSettings, response});
  },
  updateSystemSettings: (values, cb =()=>{}) => async (dispatch, getState) => {
    dispatch({type: requestUpdateSystemSettings});
    const postData = [];
    for (let key of Object.keys(values)) {
      postData.push({systemConfigId: key, value: values[key]});
    }
    const [, response] = await fetchPost('/settings/config', {systemConfigs: postData});
    dispatch({type: responseUpdateSystemSettings, response});
    if(response) {
      toastr.success(`Updated Successfully.`);
    }
    if(cb) cb();
  },
  getCommissionSettings: () => async (dispatch, getState) => {
    dispatch({type: requestCommissionSettings});
    const [, response] = await fetchGet('/settings/bet-commission');
    dispatch({type: responseCommissionSettings, response});
  },
  getBannedWords: () => async (dispatch, getState) => {
    dispatch({type: requestBannedWords});
    const [, response] = await fetchGet('/settings/banned-words');
    dispatch({type: responseBannedWords, response});
  },
  updateBannedWords: (newBannedWords) => async (dispatch, getState) => {
    dispatch({type: requestUpdateBannedWords});
    const [err, response] = await fetchPost('/settings/banned-words', newBannedWords);
    if (err) {
      toastr.error(err);
    }
    if (response) {
      toastr.success("Updated successfully");
      dispatch(actionCreators.getBannedWords());
    }
    dispatch({type: responseUpdateBannedWords, response});
  },
  getRestrictedLocations: () => async (dispatch, getState) => {
    const [, response] = await fetchGet('/settings/bets/restricted-locations');
    dispatch({type: responseSlabBetSettings, response});
  },
  addRestrictedLocations: (values, successCallback) => async (dispatch, getState) => {
    const [err, response] = await fetchPost('/settings/bets/restricted-locations', values);
    if (err) {
      toastr.error(err);
    }
    if (response) {
      toastr.success(`${values.id ? 'Updated' : 'Added'} successfully`);

      if (typeof successCallback === 'function') {
        successCallback();
      }
      // dispatch({type: responseUpdateSlabBetSettings, response:values});
      // dispatch(actionCreators.getSlabBetSettings());
    }
  },
  getCountryList: (callback) => async (dispatch, getState) => {
    dispatch({ type: requestCountryList, response : {countries : [],systemConfigs : []}});
    const [error, response] = await fetchGet('/Countries');
    if (!error) {
      dispatch({ type: responseCountryList, response });
      callback && callback()
    } else {
      dispatch({type: responseCountryList, response : {countries : [],systemConfigs : []}});
    }
   
  },

  addCountryToProfile:  function (payload,callback) {
    return async (dispatch, getState) => {
      const copyprofileCountriesData = getState().systemSettings.profileStats.profileCountriesData
      dispatch({ type: requestAddCountryIntoList, response : {...copyprofileCountriesData} });
      const [error, response] = await fetchPost('/settings/countries', payload);
      if (!error) {
        dispatch(this.getCountryList(callback))
      } else {
        dispatch({type: responseAddCountryIntoList, response : {...copyprofileCountriesData}});
      }
    }
  }
};


export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestSystemSettings:
      return {...state, loadingSystemSettings: true};
    case responseSystemSettings:
      return {...state, loadingSystemSettings: false, systemSettings: action.response};
    case requestUpdateSystemSettings:
      return {...state, updatingSystemSettings: true};
    case responseUpdateSystemSettings:
      return {
        ...state,
        updatingSystemSettings: false,
        systemSettings: action.response ? action.response : state.systemSettings
      };
    case requestCommissionSettings:
      return {...state, loadingCommissionSettings: true};
    case responseCommissionSettings:
      return {...state, loadingCommissionSettings: false, commissionSettings: action.response};
    case requestBannedWords:
      return {...state, loadingBannedWords: true};
    case responseBannedWords:
      return {...state, loadingBannedWords: false, bannedWords: action.response};
    case requestUpdateBannedWords:
      return {...state, updatingBannedWords: true};
    case responseUpdateBannedWords:
      return {...state, updatingBannedWords: false, bannedWords: action.response ? action.response : state.bannedWords};
    case responseSlabBetSettings:
      return {...state, restrictedLocations:action.response || []};
    case responseUpdateSlabBetSettings:
      return {...state, slabBetSettings:{...state.slabBetSettings,status:action.response.status}};
    case requestCountryList:
      return { ...state, profileStats: {isLoadingProfileStats : true, profileCountriesData : action.response } };
    case responseCountryList:
      return { ...state, profileStats: { profileCountriesData: action.response, isLoadingProfileStats: false } };
    case requestAddCountryIntoList:
        return { ...state, profileStats: {isLoadingProfileStats : true, profileCountriesData : action.response } };
    case responseAddCountryIntoList:
        return {...state, profileStats : {isLoadingProfileStats : false, profileCountriesData : action.response,  }};
    default:
      return state;
  }
};
