const initState = {
    statementBuilderData: [],
    statementData: {}
};

const statementBuilderReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case "GETSTATEMENTBUILDER_SUCCESS":
        case "DELETESECTION_SUCCESS":
        case "UPDATESTATEMENTBUILDER_SUCCESS":
            return { ...state, statementBuilderData: payload };
        case "GETSTATEMENTSECTIONS_SUCCESS":
            return { ...state, statementData: payload }
        default:
            return state;
    }
};

export default statementBuilderReducer;