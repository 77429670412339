import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCreateUpdateOptions,
  fetchGroupBetMarketOptions,
  updateMarketOptionFilters,
  setMarketsOptionFilters,
  updateGroupBetMarketOptionStatusById,
  deleteGroupBetMarketOptions,
  fetchCreateUpdateOptionsforMarket,
} from "../../../utility/thunks";
import {
  MarketCreationTabs,
  GBItemType,
  ItemLimitsInPage,
  gbsortByMarketOptions,
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList:"",
};

const initialState = {
  loading: true,
  marketOptionsList: {
    selectedItemIds: [],
    updatingStatusIds: [], 
    items: [],
    count: 0,
    currentPage: 1,
    errorMessage: "",
    itemsCount: 0,
    limit: 10,
    nextOffset: 0,
    numberOfPages: 1,
    offset: 0,
    itemLimitsInPage: ItemLimitsInPage[0],
    sortBy: gbsortByMarketOptions[0].key,
    searchTerm: "",
    loading: true,
  },
};

export const gbOptionsSlice = createSlice({
  name: "gb-options",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setMarketOptionFilters: (state, action) => {
      state[action.payload.ItemListName].itemLimitsInPage = action.payload.itemLimitsInPage;
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;
      state[action.payload.ItemListName].currentPage =  action.payload.currentPage;
      state[action.payload.ItemListName].sortBy =  action.payload.sortBy;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateUpdateOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCreateUpdateOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetMarketOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetMarketOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList = action.payload;
      })
      .addCase(fetchGroupBetMarketOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMarketOptionFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMarketOptionFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.selectedItemIds = action.payload.selectedItemIds;
      })
      .addCase(updateMarketOptionFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setMarketsOptionFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(setMarketsOptionFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.searchTerm = action.payload.searchTerm;
        state.marketOptionsList.currentPage = action.payload.currentPage;
        state.marketOptionsList.itemLimitsInPage = action.payload.itemLimitsInPage;
        state.marketOptionsList.sortBy =  action.payload.sortBy;
        // state.marketOptionsList.sorting =  action.payload.sortBy;
      })
      .addCase(setMarketsOptionFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGroupBetMarketOptionStatusById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGroupBetMarketOptionStatusById.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateGroupBetMarketOptionStatusById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteGroupBetMarketOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGroupBetMarketOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(deleteGroupBetMarketOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.pending, (state) => {
        state.marketOptionsList.loading = false;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.fulfilled, (state, action) => {
        state.marketOptionsList.loading = true;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.rejected, (state) => {
        state.marketOptionsList.loading = true;
      })
      ;
  },
});

export const { updateSelectedItemIds,setMarketOptionFilters} = gbOptionsSlice.actions;
export default gbOptionsSlice.reducer;
