// import internal(own) modules
import { eventsConstants } from "../../actions/events/constants";

const initState = {
  sportsData: [],
  leaguesData: {},
  // allSeasonsData: null,
  roundsData: {},
  fixturesData: {},
  teamsData: {},
  eventsData: {},
  eventCompetitorsData: {},
  playersData: {},
  seasonsData: {},
  roundsBasedOnSeason: {},
  isActionPerformed: false,
  parentCategoryData:[],
  categoryData:[],
  competitionData:[],
  selectedSeasonsData:[],
};

const eventsReducer = (state = initState, {type, payload}) => {
  switch (type) {
    case "GETSPORTS_SUCCESS":
      return {...state, sportsData: payload};
    case "GET_PARENT_CATEGORY_SUCCESS":
      return {...state, ...{parentCategoryData: payload,isActionPerformed : false}};
    case "GET_CATEGORY_SUCCESS":
      return {...state, ...{categoryData: payload,isActionPerformed : false}};
    case "GET_COMPETITION_SUCCESS":
      return {...state, competitionData: payload};
    case "GET_SEASONS_SUCCESS":
      return {...state, selectedSeasonsData: payload};
    case "GETLEAGUES_SUCCESS":
      return {...state, leaguesData: payload};
    case "GETALLSEASONS_SUCCESS":
      return {...state, allSeasonsData: payload};
    case "GETROUNDS_SUCCESS":
      return {...state, roundsBasedOnSeason: payload};
    case "GETFIXTURES_SUCCESS":
      return {...state, fixturesData: payload};
    case "GETTEAMS_SUCCESS":
      return {...state, teamsData: payload};
    case "GETSEASONS_SUCCESS":
      return {...state, seasonsData: payload};
    case "GETROUNDS_SUCCESS":
      return {...state, roundsData: payload};
    case "GETEVENTS_SUCCESS":
      return {...state, eventsData: payload};
    case "GETEVENTCOMPETITORS_SUCCESS":
      return {...state, eventCompetitorsData: payload};
    case "GETPLAYERS_SUCCESS":
      return { ...state, playersData: payload }
    
    case  eventsConstants.GET_PARENT_CATEGORY_BEGIN:
      return { ...state,  ...payload  }
    case eventsConstants.GET_PARENT_CATEGORY_FAILURE:
      return { ...state, ...payload }
      case  eventsConstants.GET_CATEGORY_BEGIN:
        return { ...state,  ...payload  }
      case eventsConstants.GET_CATEGORY_FAILURE:
          return {...state,   ...payload }
    default:
      return state;
  }
};

export default eventsReducer;
