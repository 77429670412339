const initState = {
  divisionData: {},
  teamById: {},
  fixuresDivisionData: {}
};

const divisionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_DIVISION_LIST":
      return { ...state, divisionData: payload };
    case "GET_DIVISION_LIST_FIXURES":
      return { ...state, fixuresDivisionData: payload };
    case "GET_DIVISION_BY_ID":
      return { ...state, teamById: payload };
    default:
      return state;
  }
};

export default divisionReducer;
