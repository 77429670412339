const initialState = {
  loading: false,
  error: null,
  pendingAdjustmentsList: [],
  allAdjustmentsList: []
};

export const adjustmentReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case "IS_LOADING":
      return {loading: true, pendingAdjustmentsList: null, allAdjustmentsList: null, error: null};
    case "GETPENDINGADJUSTMENTLIST_SUCCESS":
      return {...state, loading: false, pendingAdjustmentsList: payload, error: null};
    case "GETALLADJUSTMENTLIST_SUCCESS":
      return {...state, loading: false, allAdjustmentsList: payload, error: null};
    case "GETADJUSTMENT_ERROR":
      return {...state, error: payload};
    default:
      return state;
  }
};
