import { createSlice } from "@reduxjs/toolkit";
import {
  fetchGroupBetActiveMarkets,
  fetchResultGroupBetMarket,
  fetchMultipleFixtures,
  fetchBetDetailsByBetId,
  setResultingFilters,
  CancelGroupBet,
  GetMarketResultbyID,
  confirmGroupBetResult,
  fetchReverseGroupBetMarket,
} from "../../../utility/thunks";
import {  
  ItemLimitsInPage,  
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  sports: "",
  compitition: "",
  fixtures: "",  
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
};

const gbResultingActiveMarketsInitialState = {
  categoriesListSelectedCountry: "",  
  items: [],
  loading: true,
  numberOfPages: 1,  
  ...initialFilterState,
};


const initialState = {
  loading: true,
  activemarkets:  gbResultingActiveMarketsInitialState, 
  activeMarketsList: {
    errorNo: 1,
    errorMessage: "", 
    items: [],
    limit: 10,
    offset: 0,
    nextOffset: 10,
    numberOfPages: 1,
    currentPage: 1,
    count: 0,
    itemsCount: 0,
    itemLimitsInPage: ItemLimitsInPage[0],
    searchTerm: "",
  },
  FixtureDataList: {
    errorNo: 1,
    errorMessage: "", 
    items: [],
  },

  BetDetailsList:{
    betId: "",
    amount: 0,
    winAmount: 0,
    sportId: "",
    leagueId: 0,
    seasonId: 0,
    roundId: 0,
    eventId: "",
    sport:[],
    league:[],
    createdByClientId:0,
    createdByClient:[],
    acceptedByClientId:"",
    fullStatement: "",
    betStatus: "",
    betVisibility: "",
    betActivities:[],
    invitees: [],
    location: [],
    createdDate: "",
    betCloseDate: "",
    eventStartDate: "",
    currencyCode: "",
    betType: "",
    viewCount: 0,
    season:[],
    round:[],
    event:[],
    isGroupBet:1,
    isGoS: true,
    groupBetData:[],
    chatId:"",
    commentsCount:0,
    isSupervisorBet:0,
    betFeature: "",
    betFinishDate: '',
  },

  MarketResultDetails: {
    errorNo: 1,
    errorMessage: "", 
    items: [],
  },
};

export const gbResultingSlice = createSlice({
  name: "gb-resulting",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupBetActiveMarkets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetActiveMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.activeMarketsList = action.payload;
      })
      .addCase(fetchGroupBetActiveMarkets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchResultGroupBetMarket.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResultGroupBetMarket.fulfilled, (state, action) => {
        state.loading = false;
        state.activeMarketsList.errorNo = action.payload.errorNo;
        state.activeMarketsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchResultGroupBetMarket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchReverseGroupBetMarket.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReverseGroupBetMarket.fulfilled, (state, action) => {
        state.loading = false;
        state.activeMarketsList.errorNo = action.payload.errorNo;
        state.activeMarketsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchReverseGroupBetMarket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(confirmGroupBetResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmGroupBetResult.fulfilled, (state, action) => {
        state.loading = false;
        state.activeMarketsList.errorNo = action.payload.errorNo;
        state.activeMarketsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(confirmGroupBetResult.rejected, (state) => {
        state.loading = false;
      }).
      addCase(fetchMultipleFixtures.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMultipleFixtures.fulfilled, (state, action) => {
        state.loading = false;
        state.FixtureDataList = action.payload;        
      })
      .addCase(fetchMultipleFixtures.rejected, (state) => {
        state.loading = false;
      }) 
      .addCase(fetchBetDetailsByBetId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBetDetailsByBetId.fulfilled, (state, action) => {
        state.loading = false;
        state.BetDetailsList = action.payload;        
      })
      .addCase(fetchBetDetailsByBetId.rejected, (state) => {
        state.loading = false;
      }) 
      .addCase(setResultingFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(setResultingFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.activeMarketsList.searchTerm = action.payload.searchTerm;
        state.activeMarketsList.currentPage = action.payload.currentPage;
        state.activeMarketsList.itemLimitsInPage = action.payload.itemLimitsInPage;
      })
      .addCase(setResultingFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(CancelGroupBet.pending, (state) => {
        state.loading = true;
      })
      .addCase(CancelGroupBet.fulfilled, (state, action) => {
        state.loading = false;
        state.cancelGroupBetbyId = action.payload;
      })
      .addCase(CancelGroupBet.rejected, (state) => {
        state.loading = false;
      }) 
      .addCase(GetMarketResultbyID.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetMarketResultbyID.fulfilled, (state, action) => {
        state.loading = false;
        state.MarketResultDetails = action.payload;        
      })
      .addCase(GetMarketResultbyID.rejected, (state) => {
        state.loading = false;
      }) 
      ;
  },
});

export default gbResultingSlice.reducer;
