import {fetchGet, fetchPost} from "../../../commons";


export const requestReport = "REQUEST_REPORT";

export const responseAMLReport = "RESPONSE_AML_REPORT";
export const responseActivityLookUp = "RESPONSE_ACTIVITY_LOOKUP";
export const responseBetActivity = "RESPONSE_BET_ACTIVITY";


const initialState = {
  isReportLoading: false,
  amlReports: null,
  activitiesLookUp: null,
  betActivitiesReport: null
};

export const actionCreators = {
  getAMLReports: (dateFrom, dateTo, lostAmount) => async (dispatch) => {
    dispatch({type: requestReport});
    const [err, response] = await fetchPost("/reporting/lost-clients", {
      LostAmount: lostAmount,
      DateFrom: dateFrom,
      DateTo: dateTo
    });
    dispatch({type: responseAMLReport, response});
  },

  getActivitiesLookUp: () => async (dispatch) => {
    dispatch({type: requestReport});
    const [err, response] = await fetchGet("/reporting/bet-activity/lookup");
    dispatch({type: responseActivityLookUp, response});
  },

  getBetActivities: (locationId, dateFrom, dateTo, sportId, leagueId, dateFilterOnCloseDate) => async (dispatch) => {
    dispatch({type: requestReport});
    const [err, response] = await fetchPost("/reporting/bet-activity", {
      LocationId: locationId,
      DateFrom: dateFrom,
      DateTo: dateTo,
      SportId: sportId,
      LeagueId: leagueId,
      DateFilterOnCloseDate: dateFilterOnCloseDate,
    });
    dispatch({type: responseBetActivity, response});
  },
}

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestReport:
      return {...state, isReportLoading: true, amlReports: null, betActivitiesReport: null};
    case responseAMLReport:
      return {...state, isReportLoading: false, amlReports: action.response};
    case responseActivityLookUp:
      return {...state, isReportLoading: false, activitiesLookUp: action.response};
    case responseBetActivity:
      return {...state, isReportLoading: false, betActivitiesReport: action.response};
    default:
      return state;
  }
};
