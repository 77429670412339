const initState = {
  BonusBetsclsData: [],
  countriesList: [],
  statesList: [],
};

const BonusBetsclsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GETBONUSBETSCLS_SUCCESS":
      return { ...state, BonusBetsclsData: payload };
    case "GET_COUNTRY_LIST":
      return { ...state, countriesList: payload };
    case "GET_STATE_LIST":
      return { ...state, statesList: payload };
    default:
      return state;
  }
};

export default BonusBetsclsReducer;
