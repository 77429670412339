const initialState = {
  comments:[]
}
const initialReplyState = {
  reply:[]
}
const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SEND_MSG':
            return {...state, ...action.payload}
        default:
            return state
    }
}
const commentsReplyReducer = (state = initialReplyState, action) => {
  switch (action.type) {
      case 'REPLY_MESSAGE':
          return {...state, ...action.payload}
      default:
          return state
  }
}
export default commentsReducer
export {
  commentsReplyReducer
};
