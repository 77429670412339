import { LOGGER_CLEAR, LOG_ACTION } from "../../actions/logger/types";

const initState = {
  actions: []
};
const logger = (state = initState, action) => {
  switch (action.type) {
    case LOG_ACTION:
      return { ...state, actions: [...state.actions, action.payload] };
    case LOGGER_CLEAR:
      return { ...initState };
    default:
      return state;
  }
};

export default logger;
