import {apiUrl, getToken, isAnonymousRoute} from "../commons";
import browserHistory from "../history";
import { toastr } from "react-redux-toastr";

const requestCurrentAdmin = 'REQUEST_CURRENT_ADMIN';
const responseCurrentAdmin = 'RESPONSE_CURRENT_ADMIN';

const initialState = {
  loading: false,
  currentAdmin: null,
};

export const actionCreators = {
  requestCurrentAdmin: () => async (dispatch, getState) => {
    dispatch({type: requestCurrentAdmin});
    let response = await fetch(`${apiUrl}/me`, {
      headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()},
    });
    if (response.status === 401 && !isAnonymousRoute(browserHistory.location.pathname)) {
      browserHistory.replace(`/pages/login?redirect=${browserHistory.location.pathname}`);
      toastr.error("Session expired! Please login again");
    }
    if (response.status === 200) {
      response = await response.json();
    } else {
      response = "Unknown";
    }
    dispatch({type: responseCurrentAdmin, response});
  },
};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCurrentAdmin:
      return {...state, loading: true};
    case responseCurrentAdmin:
      return {...state, loading: false, currentAdmin: action.response};
    default:
      return state;
  }
};
