const initState = {
            moduleAccessData: []
        };
        
        const moduleAccessReducer = (state = initState, { type, payload }) => {
            switch (type) {
                case "GETMODULEACCESS_SUCCESS":
                    return { ...state, moduleAccessData: payload };
                default:
                    return state;
            }
        };
        
        export default moduleAccessReducer;