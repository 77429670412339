const initState = {
  betListData: [], selectedBet: null, lreasons: [], settleBetsResult: null
};

const betListReducer = (state = initState, {type, payload}) => {
  switch (type) {
    case "GETBETLIST_SUCCESS":
      return {...state, betListData: payload};
    case "GETBET_BEGIN":
      return {...state, selectedBet: null};
    case "GETBET_SUCCESS":
      return {...state, selectedBet: payload};
    case "GETLREASONS_SUCCESS":
      return {...state, lreasons: payload}
    case "GET_CANCEL_BET_REASONS_SUCCESS":
      return {...state, cancelBetReasons: payload}
    case "SETTLEBETS_BEGIN":
      return {...state, settleBetsResult: null}
    case "SETTLEBETS_SUCCESS":
      return {...state, settleBetsResult: payload}
    case "GET_BET_PARENT_CATEGORY_DATA":
      return {...state, betParentCategoryData: payload}
    default:
      return state;
  }
};

export default betListReducer;
