import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMasterTeamList,
} from '../../src/utility/thunks'

const initialState = {
  loading: true,  
  teamData: {
    teamsList: [],
    loading:true,
    count: 0,
    totalCount: 0,
    currentPage: 0,
    numberofPages: 0,
    totalDBCount: 0,
    errorNo: 0,
    errorMessage: "",
  }, 
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setCategoriesStatusFilters: (state, action) => {
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;
    },
    updateFixtureTeamList: (state, action) => {
      state[action.payload.ItemListName] = action.payload.replaceTeamList;
    },
  },

  extraReducers: (builder) => {
    builder
      
      .addCase(fetchMasterTeamList.pending, (state) => {
        state.teamData.loading = true;
      })
      .addCase(fetchMasterTeamList.fulfilled, (state, action) => {
        state.teamData.loading = false;
        state.teamData = action.payload;
      })
      .addCase(fetchMasterTeamList.rejected, (state, action) => {
        state.teamData.loading = false;
        state.teamData.errorNo = action.payload.errorNo;
        state.teamData.errorMessage = action.payload.errorMessage;
      })      
      ;
  },
});

export const { updateSelectedItemIds, setCategoriesStatusFilters,updateFixtureTeamList } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;
