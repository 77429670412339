import { createSlice } from "@reduxjs/toolkit";
import {
  UpdateTippingCategoryStatus,
  UpdateTippingCompetitionStatus,
  UpdateTippingEventStatus,
  UpdateTippingRoundsStatus,
  fetchTipingFixtures,
  fetchTippingRounds,
  getTippingDataForResulting,
  resultTippingBets,
  getAllSports,
  getTippingResultingRounds,
  getAllCompetitions,
  getAllFixtures,
  getAllSeasons,
  createTippingBet,
} from "../../../utility/thunks";
import {  
  GBsortByOptions,
  ItemLimitsInPage, TippingsortByFixtureOptions, TippingsortByOptions, TippingsortByRoundOptions,  
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  responseSearchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList: "",
};

const initialState = {
  loading: false,
  RoundResults: {
    loading: false,
    errorNo: 1,
    errorMessage: "", 
    TippingResultingData: [],
    searchTerm: "",
    sports: "",
    compitition: "",
    fixtures: "",
    itemLimitsInPage: ItemLimitsInPage[0],
    offset: 0,  
    count: 0,
    pageNo: 0,
    totalItems: 0,
    totalPages: 0,
    errorNo: 0,   
    errorMessage: "",
  },
  catogoriesList: {
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: GBsortByOptions[0].key,
    sortByTipping: TippingsortByOptions[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  compititionList: {
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: GBsortByOptions[0].key,
    sortByTipping: TippingsortByOptions[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  roundList: {
    selectedItemIds: [],
    updatingStatusIds: [],
    selectedCountry: "",
    parents: [],    
    items: [],
    loading: true,
    numberOfPages: 1,
    errorNo: 0,
    errorMessage: "",
    sortBy: TippingsortByRoundOptions[0].key,
    sorting: TippingsortByRoundOptions[0].key,
    ...initialFilterState,
  },
  tippingFixtureList: {
    selectedItemIds: [],
    updatingStatusIds: [],
    selectedCountry: "",
    parents: [],    
    items: [],
    loading: true,
    numberOfPages: 1,
    errorNo: 0,
    errorMessage: "",
    sortBy: TippingsortByFixtureOptions[0].key,
    sorting: TippingsortByFixtureOptions[0].key,
    ...initialFilterState,
  },
  categorySportData: {
    loading: false,
    sports:[],
    errorNo: 0,   
    errorMessage: "",
  },
  categoryCompetitionData: {
    loading: false,
    competitions:[],
    errorNo: 0,   
    errorMessage: "",
  },
  categorySeasonData: {
    loading: false,
    seasons:[],
    errorNo: 0,   
    errorMessage: "",
  },
  categoryFixtureData: {
    loading: false,
    fixtures:[],
    errorNo: 0,   
    errorMessage: "",
  },
  resultingRounds: {       
    items: [],
    loading: false,    
    errorNo: 0,
    errorMessage: "",    
  },
  tippingBetCreation: {       
    items: [],
    loading: false,    
    errorNo: 0,
    errorMessage: "",    
  },
};

export const tippingSlice = createSlice({
  name: "tipping",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setMarketCreationFilters: (state, action) => {
      state[action.payload.ItemListName].itemLimitsInPage = action.payload.itemLimitsInPage;
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;      
      state[action.payload.ItemListName].currentPage =  action.payload.currentPage;
      state[action.payload.ItemListName].sortBy =  action.payload.sortBy;
      state[action.payload.ItemListName].filterDateFrom =  action.payload.filterDateFrom;
      state[action.payload.ItemListName].filterDateTo =  action.payload.filterDateTo;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(getTippingDataForResulting.pending, (state) => {
        state.RoundResults.loading = true;
      })
      .addCase(getTippingDataForResulting.fulfilled, (state, action) => {
        state.RoundResults.loading = false;
        state.RoundResults=action.payload;
      })
      .addCase(getTippingDataForResulting.rejected, (state) => {
        state.RoundResults.loading = false;
      })
      .addCase(UpdateTippingCategoryStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateTippingCategoryStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateTippingCategoryStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.catogoriesList.errorNo = action.payload.errorNo;
        state.catogoriesList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateTippingCompetitionStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateTippingCompetitionStatus.fulfilled, (state, action) => {
          state.loading = false;          
          state.compititionList.errorNo = action.payload.errorNo;
          state.compititionList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateTippingCompetitionStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchTippingRounds.pending, (state) => {
        state.roundList.loading = true;
      })
      .addCase(fetchTippingRounds.fulfilled, (state, action) => {
        state.roundList.loading = false;
        state.roundList = action.payload;
      })
      .addCase(fetchTippingRounds.rejected, (state) => {
        state.roundList.loading = false;
      })
      .addCase(UpdateTippingRoundsStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateTippingRoundsStatus.fulfilled, (state, action) => {
          state.loading = false;          
          state.roundList.errorNo = action.payload.errorNo;
          state.roundList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateTippingRoundsStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchTipingFixtures.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTipingFixtures.fulfilled, (state, action) => {
        state.loading = false;
        state.tippingFixtureList = action.payload;
      })
      .addCase(fetchTipingFixtures.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateTippingEventStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateTippingEventStatus.fulfilled, (state, action) => {
          state.loading = false;
          state.tippingFixtureList.errorNo = action.payload.errorNo;
          state.tippingFixtureList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateTippingEventStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resultTippingBets.pending, (state) => {
        state.RoundResults.loading = true;
      })
      .addCase(resultTippingBets.fulfilled, (state, action) => {
        state.RoundResults.loading = false;
        state.RoundResults=action.payload;
      })
      .addCase(resultTippingBets.rejected, (state) => {
        state.RoundResults.loading = false;
      })
      .addCase(getAllSports.pending, (state) => {
        state.categorySportData.loading = true;
      })
      .addCase(getAllSports.fulfilled, (state, action) => {
        state.categorySportData.loading = false;
        state.categorySportData=action.payload;
      })
      .addCase(getAllSports.rejected, (state) => {
        state.categorySportData.loading = false;
      })
      .addCase(getAllCompetitions.pending, (state) => {
        state.categoryCompetitionData.loading = true;
      })
      .addCase(getAllCompetitions.fulfilled, (state, action) => {
        state.categoryCompetitionData.loading = false;
        state.categoryCompetitionData=action.payload;
      })
      .addCase(getAllCompetitions.rejected, (state) => {
        state.categoryCompetitionData.loading = false;
      })
      .addCase(getAllSeasons.pending, (state) => {
        state.categorySeasonData.loading = true;
      })
      .addCase(getAllSeasons.fulfilled, (state, action) => {
        state.categorySeasonData.loading = false;
        state.categorySeasonData=action.payload;
      })
      .addCase(getAllSeasons.rejected, (state) => {
        state.categorySeasonData.loading = false;
      })
      .addCase(getAllFixtures.pending, (state) => {
        state.categoryFixtureData.loading = true;
      })
      .addCase(getAllFixtures.fulfilled, (state, action) => {
        state.categoryFixtureData.loading = false;
        state.categoryFixtureData=action.payload;
      })
      .addCase(getAllFixtures.rejected, (state) => {
        state.categoryFixtureData.loading = false;
      })
      .addCase(getTippingResultingRounds.pending, (state) => {
        state.resultingRounds.loading = true;
      })
      .addCase(getTippingResultingRounds.fulfilled, (state, action) => {
        state.resultingRounds.loading = false;
        state.resultingRounds = action.payload;
      })
      .addCase(getTippingResultingRounds.rejected, (state) => {
        state.resultingRounds.loading = false;
      })
      .addCase(createTippingBet.pending, (state) => {
        state.tippingBetCreation.loading = true;
      })
      .addCase(createTippingBet.fulfilled, (state, action) => {
        state.tippingBetCreation.loading = false;
        state.tippingBetCreation = action.payload;
      })
      .addCase(createTippingBet.rejected, (state) => {
        state.tippingBetCreation.loading = false;
      })
      ;
  },
});
export const { updateSelectedItemIds, setMarketCreationFilters } =
tippingSlice.actions;
export default tippingSlice.reducer;
