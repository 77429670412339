const initialState = {
  pendingWithdrawalData: [],
  allWithdrawalData: [],
  pendingWithheldData:[],
  batchDetails: [],
  loading: false,
  error: null,
};

export const pendingWithDrawalReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {

    case "IS_LOADING":
      return {
        loading: true,
        allWithdrawalData: null,
        pendingWithdrawalData: null,
        pendingWithheldData: null,
        batchDetails: null,
      };
    case "GETPENDINGWITHDRAWALLIST_SUCCESS":
      return { ...state, loading: false, pendingWithdrawalData: payload };
    case "GETPENDINGWITHHELDLIST_SUCCESS":
      return { ...state, loading: false, pendingWithheldData: payload };
    case "GETALLWITHDRAWALLIST_SUCCESS":
      return { ...state, loading: false, allWithdrawalData: payload };
    case "GETPROCESSEDWITHDRAWAL_LIST":
      return { ...state, loading: false, batchDetails: payload };
    case "APPROVE_REJECT_WITHDRAWAL_ERROR":
      return { ...state, error: payload };
    default:
      return state;
  }
};
