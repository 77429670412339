export const CMSConstants = {
  CMS_CONTENT_LIST: "CMS_CONTENT_LIST",
  CMS_UPLOAD_BEGIN: "CMS_UPLOAD_BEGIN",
  CMS_UPLOAD_SUCCESS: "CMS_UPLOAD_SUCCESS",
  CMS_UPLOAD_FAILURE: "CMS_UPLOAD_FAILURE",
  GET_CMS_CATEGORY_LIST: "GET_CMS_CATEGORY_LIST",
  GET_CATEGORY_COMPETITION_LIST: "GET_CATEGORY_COMPETITION_LIST",
  GET_SEASON_BATS_AND_FIXTURES: 'GET_SEASON_BATS_AND_FIXTURES',
  LOAD_CMS:'LOAD_CMS',
  GET_COMPETITION_SEASON_LIST:'GET_COMPETITION_SEASON_LIST',
  GET_CMS_INFLUENCERS_LIST: "GET_CMS_INFLUENCERS_LIST",
  GET_CMS_DISPLAYONSCREEN_LIST: "GET_CMS_DISPLAYONSCREEN_LIST",
  CMS_PRIORITY_STATUS: "CMS_PRIORITY_STATUS",
  SET_SELECTED_COUNTRY: "SET_SELECTED_COUNTRY",
  SET_CMS_CATEGORY_TAB : 'SET_CMS_CATEGORY_TAB'
};
